import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import frFlag from './images/frFlag.svg';
import gbFlag from './images/gbFlag.svg';
import homepageLandscapeImage from './images/homepageLandscape.webp';
import homepagePortraitImage from './images/homepagePortrait.webp';

const styles = () => ({
  root: {
    height: '100%',
  },
  image: {
    maxWidth: '90%',
    maxHeight: '60vh',
    margin: 0,
    '@media (orientation: landscape)': {
      maxHeight: '70vh',
    },
  },
  imagePortrait: {
    display: 'none',
    minWidth: '90%',
    maxWidth: '100%',
    '@media (orientation: portrait)': {
      display: 'inline-block',
    },
  },
  imageLandscape: {
    display: 'none',
    '@media (orientation: landscape)': {
      display: 'inline-block',
    },
  },
  buttons: {
    '@media (orientation: portrait)': {
      marginTop: '5vh',
    },
  },
  flags: {
    width: '30%',
    margin: '2%',
    display: 'inline-block',
    cursor: 'pointer',
    '@media (orientation: landscape)': {
      width: '10%',
    },
  },
  flagsImg: {
    width: '100%',
  },
});

const StyledGrid = withStyles({
  container: {
    minHeight: '100%',
    textAlign: 'center',
    paddingTop: '5vh',
    '@media (orientation: portrait) and (max-width: 600px)': {
      paddingTop: 0,
      alignItems: 'normal',
    },
  },
})(Grid);

class Homepage extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  goToNexStep() {
    this.props.history.push('/videos');
  }

  async chooseLanguage(lang) {
    this.props.setLanguage(lang);
    this.goToNexStep();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <StyledGrid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <img src={homepageLandscapeImage} className={`${classes.image} ${classes.imageLandscape}`} alt="" />
            <img src={homepagePortraitImage} className={`${classes.image} ${classes.imagePortrait}`} alt="" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>Choose</strong> your language
              <br />
              <strong>Sélectionnez</strong> votre langue
            </Typography>
            <div className={classes.buttons}>
              <div
                role="button"
                onClick={() => this.chooseLanguage('fr')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={frFlag} className={classes.flagsImg} alt="Français" />
              </div>
              <div
                role="button"
                onClick={() => this.chooseLanguage('en')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={gbFlag} className={classes.flagsImg} alt="English" />
              </div>
            </div>
          </Grid>
        </StyledGrid>
      </div>
    );
  }
}

Homepage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLanguage: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    buttons: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imagePortrait: PropTypes.string.isRequired,
    imageLandscape: PropTypes.string.isRequired,
    flags: PropTypes.string.isRequired,
    flagsImg: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Homepage));
