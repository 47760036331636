import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {
  Button, Card, CardActions, CardContent, IconButton, MenuItem, Modal, Paper,
} from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import SettingsIcon from '@material-ui/icons/Settings';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import Select from '@material-ui/core/Select';
import VideoRecorder from '../VideoRecorder/VideoRecorder';
import VideoUploader from '../VideoUploader/VideoUploader';
import colors from '../../helpers/colors';
import supportedBrowsers from '../../supportedBrowsers';
import { getMediaDevices, mobileDetect } from '../../helpers/helpers';

const styles = {
  content: {
    position: 'relative',
  },
  buttons: {
    color: colors.grey,
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: '0 20px',
    maxWidth: 800,
    padding: '2vw',
    background: colors.greyLight,
  },
  card: {
    marginTop: 10,
    marginBottom: 10,
    background: colors.white,
  },
  error: {
    border: `10px solid ${colors.red}`,
    padding: 20,
    color: colors.red,
    margin: '40px 0',
  },
  settingsIcon: {
    filter: `drop-shadow(0px 0px 0px ${colors.white})`, // Allows to see the icon even with a black background
  },
};

class VideoUploaderChoice extends Component {
  constructor(props) {
    super(props);

    const defaultChoice = this.getDefaultChoice();
    this.state = {
      choice: defaultChoice, // null | 'uploader' | 'recorder'
      modalOpened: defaultChoice === null,
      error: null, // null | Error
      devices: [],
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    if (this.state.choice === 'recorder') {
      this.fetchDevices(); // load devices names if the user already approved the autorization during a previous visit
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.choice !== prevState.choice) {
      this.props.setVideoRecorderChoice(`${this.state.choice}_default`);
    }

    if (
      (this.state.choice === 'recorder' && this.state.choice !== prevState.choice) // the user already approved the autorization in a past visit
      || (this.props.lastMediaPermissionAllowDate !== prevProps.lastMediaPermissionAllowDate) // the user just approved the autorisation
    ) {
      this.fetchDevices();
    }
  }

  async fetchDevices() {
    const devices = await getMediaDevices(false); // Not asking for permission in order to avoid conflict with recorder streaming
    this.setState({ devices });
  }

  getDefaultChoice() {
    if (this.props.config.videoRecorderChoice) {
      return this.props.config.videoRecorderChoice.replace('_default', '');
    }

    if (mobileDetect.mobile()) {
      return 'uploader';
    }

    // Old browsers
    if (!supportedBrowsers.test(navigator.userAgent)) {
      return 'uploader';
    }

    return 'recorder';
  }

  setVideoRecorderChoice = (choice) => {
    this.props.setVideoRecorderChoice(choice);
    this.setState({ choice: choice.replace('_default', '') });
  }

  render() {
    const audioDevices = this.state.devices.filter(device => device.kind === 'audioinput');
    const videoDevices = this.state.devices.filter(device => device.kind === 'videoinput');

    return (
      <div style={styles.content}>
        {(!this.props.config.videoRecorderChoice || this.props.config.videoRecorderChoice.indexOf('_default') > 0) && (
        <div style={styles.buttons}>
          <IconButton aria-label="Settings" onClick={() => this.setState({ modalOpened: true })}>
            <SettingsIcon style={styles.settingsIcon} />
          </IconButton>
        </div>
        )}

        {this.state.error && (
          <div style={styles.error}>
            <Typography>{this.state.error.message}</Typography>
            <Typography><Trans i18nKey="videoUploaderChoice.errorInChildren" /></Typography>
          </div>
        )}
        {!this.state.error && this.state.choice === 'uploader' && <VideoUploader {...this.props} />}
        {!this.state.error && this.state.choice === 'recorder' && (
          <VideoRecorder {...this.props} setVideoRecorderChoice={this.setVideoRecorderChoice} setLastMediaPermissionAllowDate={this.props.setLastMediaPermissionAllowDate} />
        )}

        <Modal
          open={this.state.modalOpened}
          onClose={() => this.setState({ modalOpened: false })}
          style={styles.modal}
        >
          <Paper elevation={3} style={styles.paper}>
            {this.state.choice !== 'recorder' && this.props.config.videoRecorderChoice !== 'uploader' && (
              <Card style={styles.card}>
                <CardContent>
                  <Typography><Trans i18nKey="videoUploaderChoice.useRecorder" /></Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => this.setState({ choice: 'recorder', modalOpened: false, error: null })} type="button" variant="outlined" color="secondary">
                    <Trans i18nKey="videoUploaderChoice.useRecorderButton" />
                  </Button>
                </CardActions>
              </Card>
            )}
            {this.state.choice !== 'uploader' && this.props.config.videoRecorderChoice !== 'recorder' && (
              <Card style={styles.card}>
                <CardContent>
                  <Typography><Trans i18nKey="videoUploaderChoice.useUploader" /></Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => this.setState({ choice: 'uploader', modalOpened: false, error: null })} type="button" variant="outlined" color="secondary">
                    <Trans i18nKey="videoUploaderChoice.useUploaderButton" />
                  </Button>
                </CardActions>
              </Card>
            )}
            {this.state.choice === 'recorder' && videoDevices.length > 0 && (
              <Card style={styles.card}>
                <CardContent>
                  <Typography><Trans i18nKey="videoUploaderChoice.videoDeviceChoice" /></Typography>
                </CardContent>
                <CardActions>
                  <VideocamOutlinedIcon />
                  <Select displayEmpty value={this.props.config.videoDeviceId || ''} onChange={e => this.props.setVideoDeviceId(e.target.value)} defaultValue="">
                    <MenuItem value="" aria-label="default">{ this.props.t('videoUploaderChoice.defaultChoice') }</MenuItem>
                    {videoDevices.map(device => (
                      <MenuItem key={device.deviceId} value={device.deviceId}>{device.label || device.deviceId}</MenuItem>
                    ))}
                  </Select>
                </CardActions>
              </Card>
            )}
            {this.state.choice === 'recorder' && audioDevices.length > 0 && (
              <Card style={styles.card}>
                <CardContent>
                  <Typography><Trans i18nKey="videoUploaderChoice.audioDeviceChoice" /></Typography>
                </CardContent>
                <CardActions>
                  <MicIcon />
                  <Select displayEmpty value={this.props.config.audioDeviceId || ''} onChange={e => this.props.setAudioDeviceId(e.target.value)} defaultValue="">
                    <MenuItem value="" aria-label="default">{ this.props.t('videoUploaderChoice.defaultChoice') }</MenuItem>
                    {audioDevices.map(device => (
                      <MenuItem key={device.deviceId} value={device.deviceId}>{device.label || device.deviceId}</MenuItem>
                    ))}
                  </Select>
                </CardActions>
              </Card>
            )}

          </Paper>
        </Modal>
      </div>
    );
  }
}

VideoUploaderChoice.defaultProps = {
  onValidate: null,
  defaultVideoUrl: null,
  lastMediaPermissionAllowDate: null,
};

VideoUploaderChoice.propTypes = {
  config: PropTypes.shape().isRequired,
  lastMediaPermissionAllowDate: PropTypes.shape(),
  onValidate: PropTypes.func,
  defaultVideoUrl: PropTypes.string,
  setVideoRecorderChoice: PropTypes.func.isRequired,
  setVideoDeviceId: PropTypes.func.isRequired,
  setAudioDeviceId: PropTypes.func.isRequired,
  setLastMediaPermissionAllowDate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(VideoUploaderChoice);
