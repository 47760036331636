import localForage from 'localforage';
import MobileDetect from 'mobile-detect';
import * as Sentry from '@sentry/react';

export const setStatePromise = (that, newState) => new Promise((resolve) => {
  that.setState(newState, () => resolve());
});

export const addVideoAssets = (store, video) => {
  const baseUrl = store.getState().env.ASSETS_BASE_URL;

  video.urls = {
    mp4: `${baseUrl}/videos/${video.filename}.mp4`,
    webm: `${baseUrl}/videos/${video.filename}.webm`,
    thumb: `${baseUrl}/pictures/${video.filename}.jpg`,
  };

  return video;
};

export const clearBlobStorage = () => localForage.clear();

export const persistBlobUrl = async (blobUrl) => {
  if (!blobUrl) {
    return;
  }

  const blob = await fetch(blobUrl).then(r => r.blob());
  await localForage.setItem(blobUrl, blob);
};

export const fetchBlobByUrl = async (blobUrl) => {
  try {
    return await fetch(blobUrl).then(r => r.blob());
  } catch (e) {
    // Not in current session
  }

  return localForage.getItem(blobUrl);
};

export const fetchBlobAndGetUrl = async (blobUrl) => {
  let blob;

  try {
    blob = await fetchBlobByUrl(blobUrl);
  } catch (e) {
    // Not in current session
  }

  if (!blob) {
    throw Error('Video not found');
  }

  return URL.createObjectURL(blob);
};

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
export const isApple = mobileDetect.is('iOS') || mobileDetect.is('iPadOS') || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const removeDuplicates = (data, prop) => data.filter((obj, pos, arr) => !!obj[prop] && arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);

export const getMediaDevices = async (askPermissionForNames, setLastMediaPermissionAllowDate) => {
  if (!navigator.mediaDevices?.enumerateDevices) {
    return Promise.reject(new Error('MediaDevices feature not supported'));
  }

  // getUserMedia allows to have the devices names, but asks for the user permission
  let stream;
  if (askPermissionForNames) {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    setLastMediaPermissionAllowDate && setLastMediaPermissionAllowDate();
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  if (stream) {
    stream.stop();
  }

  // Fix Firefox issue
  return removeDuplicates(devices, 'deviceId');
};

function readBlobPromised(blob) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(blob);
  });
}

// Fix video seeking issues
// This function is a copy of the one contained in RecordRTC.js, but with https://github.com/legokichi/ts-ebml/issues/33 fix and with promise support
export const getSeekableBlob = (inputBlob) => {
  if (isApple) {
    // Can fail on iOS: https://github.com/muaz-khan/RecordRTC/issues/719
    // Can also fail on Mac: Chrome or Safari
    return Promise.resolve(inputBlob);
  }

  // EBML.js copyrights goes to: https://github.com/legokichi/ts-ebml
  if (typeof window.EBML === 'undefined') {
    throw new Error('EBML has not been found');
  }

  const reader = new window.EBML.Reader();
  const decoder = new window.EBML.Decoder();
  const { tools } = window.EBML;

  return readBlobPromised(inputBlob)
    .then((result) => {
      let ebmlElms = decoder.decode(result);

      // see https://github.com/legokichi/ts-ebml/issues/33#issuecomment-888800828
      const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd'];
      ebmlElms = ebmlElms?.filter((elm) => validEmlType.includes(elm.type));

      ebmlElms.forEach((element) => {
        reader.read(element);
      });
      reader.stop();
      const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
      const body = result.slice(reader.metadataSize);
      return new Blob([refinedMetadataBuf, body], {
        type: 'video/webm',
      });
    });
};

export const sendErrorToSentry = (error) => {
  console.error(error);
  Sentry.captureException(error);
};
