import { connect } from 'react-redux';
import VideosList from '../../components/VideoView/VideosList';
import { resetUserRecord } from '../actions';
import i18n from '../../i18n';

const mapStateToProps = state => ({
  videos: state.videos,
  currentLanguage: i18n.language,
});

const mapDispatchToProps = dispatch => ({
  resetUserRecord: () => dispatch(resetUserRecord()),
});

const VideosListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideosList);

export default VideosListContainer;
