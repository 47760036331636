const app = (state = '', action) => {
  switch (action.type) {
    case 'SET_LAST_MEDIA_PERMISSION_ALLOW_DATE':
      return { ...state, lastMediaPermissionAllowDate: action.value };
    default:
      return state;
  }
};

export default app;
