import Typography from '@material-ui/core/Typography';
import React from 'react';

function NotFound() {
  return (
    <div>
      <Typography variant="h3">Page not found :&apos;(</Typography>
    </div>
  );
}

export default NotFound;
