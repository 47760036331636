import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import NextButtonSecondary from '../BottomButtons/NextButtonSecondary';
import askSideImg from './images/ask.webp';
import './UserRecord.css';
import colors from '../../helpers/colors';

const styles = () => ({
  choiceText: {
    backgroundColor: colors.creme,
    overflow: 'hidden',
    padding: '6%',
    paddingLeft: '40%',
    position: 'relative',
    marginTop: '8vh',
  },
  sideImg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    minHeight: '100%',
    maxHeight: '120%',
    maxWidth: '35%',
  },
  question: {
    fontWeight: 600,
    fontSize: '4vh',
    '@media (orientation: portrait)': {
      fontSize: '2vh',
    },
  },
});

class VideoNewQuestion extends Component {
  skipRecord() {
    this.props.resetVideoQuestion();
    this.props.history.push('/informations');
  }

  goToNextStep() {
    this.props.history.push('/record');
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.choiceText}>
          <Typography variant="h3" className={classes.question} gutterBottom><Trans i18nKey="videoNewQuestion.choiceTitle" /></Typography>
          <Typography variant="h3" className={classes.question} gutterBottom>
            <Trans i18nKey="videoNewQuestion.choiceQuestion" />
          </Typography>
          <img src={askSideImg} alt="" className={classes.sideImg} />
        </div>

        <ButtonsContainer>
          <NextButton onClick={() => this.goToNextStep()}>
            <Trans i18nKey="videoNewQuestion.askMyQuestion" />
          </NextButton>
          <NextButtonSecondary onClick={() => this.skipRecord()}>
            <Trans i18nKey="videoNewQuestion.skipMyQuestion" />
          </NextButtonSecondary>
        </ButtonsContainer>
      </>
    );
  }
}

VideoNewQuestion.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  resetVideoQuestion: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    choiceText: PropTypes.string.isRequired,
    sideImg: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(VideoNewQuestion));
