import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  icon: {
    fontSize: 50,
    height: 60,
    position: 'absolute',
    left: 0,
    top: 'calc( 50% - 30px )',
  },
};

export const commontButtonStyle = theme => ({
  zIndex: 1,
  boxShadow: 'none',
  textAlign: 'left',
  padding: '20px 10px',
  width: '45%',
  fontSize: 'min(4vw, 2.8rem)',
  minHeight: 80,
  borderRadius: 0,
  '@media (orientation: landscape)': {
    fontSize: '2.7vh',
    maxWidth: '350px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '48%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '80%',
    fontSize: 'max(2.5vw, 2.5vh)',
  },
});

const StyledLeftButton = withStyles(theme => ({
  root: {
    ...commontButtonStyle(theme),
    paddingLeft: 60,
    [theme.breakpoints.down('xs')]: {
      ...commontButtonStyle(theme)[theme.breakpoints.down('xs')],
      position: 'absolute',
      top: 120,
    },
  },
  label: {
    display: 'block',
  },
}))(Button);

function PreviousButton({ onClick, disabled, children }) {
  const handleClick = (e) => {
    if (!onClick) {
      return;
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <StyledLeftButton color="secondary" size="large" variant="contained" onClick={handleClick} disabled={disabled}>
      <KeyboardArrowLeftIcon style={styles.icon} />
      {children}
    </StyledLeftButton>
  );
}

PreviousButton.defaultProps = {
  onClick: null,
  disabled: false,
  children: <span />,
};

PreviousButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default PreviousButton;
