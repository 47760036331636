import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const StyledGridListTile = withStyles({
  tile: {
    cursor: 'pointer',
  },
})(GridListTile);

class VideosList extends Component {
  componentDidMount() {
    this.props.resetUserRecord();
  }

  getVideosList() {
    const { videos, currentLanguage } = this.props;

    return videos.filter(video => !video.language || (video.language || '').toLowerCase() === currentLanguage.toLowerCase());
  }

  render() {
    const { width, history } = this.props;

    let columns = 3;
    if (isWidthDown('xs', width)) {
      columns = 1;
    } else if (isWidthDown('sm', width)) {
      columns = 2;
    }

    return (
      <div>
        <div className="page-title">
          <Typography variant="h1">
            <Trans i18nKey="videoList.title" />
          </Typography>
        </div>

        <GridList cellHeight="auto" cols={columns} spacing={10}>
          {this.getVideosList().map(video => (
            <StyledGridListTile key={video.id} cols={1} onClick={() => history.push(`/videos/${video.id}`)}>
              <img src={video.urls.thumb} alt={video.id} style={{ width: '100%', opacity: 0, transition: 'opacity .3s' }} onLoad={e => e.target.style.opacity = 1} />
            </StyledGridListTile>
          ))}
        </GridList>
      </div>
    );
  }
}

VideosList.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      thumb: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  resetUserRecord: PropTypes.func.isRequired,
};

export default withRouter(withWidth()(VideosList));
