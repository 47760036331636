import { combineReducers } from 'redux';
import app from './app';
import videos from './videos';
import config from './config';
import userRecord from './userRecord';
import env from './env';

export default combineReducers({
  app,
  videos,
  config,
  env,
  userRecord,
});
