import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DownloadButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (!this.props.onClick) {
      return;
    }

    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    const currentSrc = this.props.player ? this.props.player.currentSrc : null; // eslint-disable-line react/prop-types

    return (
      <a // eslint-disable-line jsx-a11y/anchor-has-content
        ref={
          (c) => {
            this.button = c;
          }
        }
        aria-label="Download"
        className="video-react-control video-react-button"
        href={currentSrc}
        download
        style={{
          backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDloLTRWM0g5djZINWw3IDcgNy03ek01IDE4djJoMTR2LTJINXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==)', // eslint-disable-line max-len
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        tabIndex="0"
        onClick={this.handleClick}
      />
    );
  }
}

DownloadButton.defaultProps = {
  onClick: null,
};

DownloadButton.propTypes = {
  player: PropTypes.shape(), // eslint-disable-line react/require-default-props
  onClick: PropTypes.func,
};
