import { connect } from 'react-redux';
import { setVideoQuestion } from '../actions';
import VideoNewQuestion from '../../components/UserRecord/VideoNewQuestion';

const mapStateToProps = state => ({
  previousVideoUrl: state.userRecord.questionVideoBlobUrl,
});

const mapDispatchToProps = dispatch => ({
  setVideoQuestion: (videoBlobUrl, videoCameraFilename) => dispatch(setVideoQuestion(videoBlobUrl, videoCameraFilename)),
});

const VideoNewQuestionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoNewQuestion);

export default VideoNewQuestionContainer;
