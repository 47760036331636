import { enGB as enDates, fr as frDates } from 'date-fns/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import countries from './helpers/countries';

const resources = {
  en: {
    translation: {
      loading: 'Loading...',
      layout: {
        cancelTitle: 'Back to home?',
        cancelText: 'Do you really want to go back to the home page and lose the current session?',
        cancelConfirm: 'Go back to the home page',
        cancelCancel: 'Cancel',
        backToHomeIn: 'Back to home in {{secondsBeforeFinish}}s',
        obsoleteBrowser: 'Your browser is not supported. You should update it before using this app.',
        obsoleteBrowserMobile: 'On a mobile phone, you should use your phone\'s default browser, as Safari on iOS or Chrome on Android.',
        obsoleteBrowserDesktop: 'On a desktop, you should use the Chrome browser.',
      },
      videoList: {
        title: '<strong>Who</strong> would you like to<br /><strong>listen to</strong>?',
      },
      videoView: {
        chooseAnother: '<strong>Choose</strong><br />another question',
        answer: '<strong>Answer</strong><br />this question',
      },
      videoUploaderChoice: {
        useUploader: 'Use the button bellow in order to send a video from you device. On a mobile, you can also use this mode in order to use your device\'s camera app.',
        useUploaderButton: 'Send a video from my device',
        useRecorder: 'If your browser is up-to-date, you can record your video from this web app.',
        useRecorderButton: 'Record a video from this app',
        errorInChildren: 'An error happened. You can choose another sending mode from the options menu.',
        videoDeviceChoice: 'You can choose the video device you want to use',
        audioDeviceChoice: 'You can choose the audio device you want to use',
        defaultChoice: 'Automatic',
      },
      videoUploader: {
        error: 'An error happened when uploading the file.',
      },
      videoRecorder: {
        begin: 'GO',
        start: 'START',
        stop: 'STOP',
        error: 'Error',
        useUploader: 'If you cannot use your webcam with this app, use the button bellow in order to send a video from your device.',
        cameraError: 'Camera error: {{message}}',
        noUserMedia: 'Your browser cannot stream from your webcam. {{message}}',
        noUserMediaHttp: 'You must access this app with HTTPS.',
        noUserMediaUnsupported: 'You should use an up-to-date browser.',
        NotFoundError: 'No webcams have been found',
        NotAllowedError: 'You must allow this app to access your camera',
        NotReadableError: 'Webcam access is allowed but not possible. Most commonly this happens because the webcam is already in use by another app. Please close them and retry.',
        restartRecording: '<strong>Restart</strong><br />recording',
        validateRecord: '<strong>Validate</strong><br />recording',
      },
      videoAnswer: {
        answerQuestion: '<strong>Answer</strong> this question:',
        answerFirstnameQuestion: "Answer <strong>{{firstname}}</strong>'s question:",
        gotToEnd: 'For your answer to be validated, <strong>please</strong> go to the last step of the app',
      },
      videoNewQuestion: {
        choiceTitle: '<strong>Thanks!</strong>',
        choiceQuestion: 'And you,<br />what question<br />would you like<br />to ask the world?',
        askMyQuestion: '<strong>Ask</strong><br />my question',
        skipMyQuestion: "I don't want to ask a question",
        askYourQuestion: '<strong>Ask</strong> your<br /><strong>question</strong>:',
      },
      informationsForm: {
        title: 'One last effort<br />to validate <br-portrait />you contribution.',
        subtitle: 'Without it, we won\'t be able to use your answers, and that would be a shame.',
        firstname: 'First Name',
        lastname: 'Surname',
        job: 'Occupation',
        email: 'E-mail',
        birth: 'Date of birth',
        country: 'Country',
        cgu: 'I give my consent to use my image',
        cguLink: 'Read the article on image rights',
        newsletter: 'I want to receive your newsletter',
        submit: '<strong>Validate</strong>',
        badBirth: 'Invalid date format',
        closeCguText: 'Close',
        cguText: `<p>
          By checking the box “I give consent to use my image”, I hereby give my consent to the association “20 Questions to the World”,
          represented by its President Cyril Bruyelle, with headquarters in 41 rue de lancry, 75010 Paris, to use my image and any statements
          recorded in this application, in its publications, advertising or other media activities (including the Internet),
          without expectation of compensation or other remuneration, now or in the future,
        </p>
        <p>
          This consent includes, but is not limited to:
        </p>
        <ul>
          <li>Permission to interview, film, photograph, tape, or otherwise make a video reproduction of me and/or record my voice;</li>
          <li>
            Permission to use quotes from the interview(s) (or excerpts of such quotes), the film, photograph(s), tape(s) or reproduction(s) of me,
            and/or recording of my voice, in part or in whole, in its publications in electronic media (including the Internet), or during public events.
          </li>
        </ul>
        <p>
          This consent is given in perpetuity, and does not require prior approval by me. 
        </p>`,
      },
      sendForm: {
        errorEmptyForm: 'Error: you have not filled the form nor answer the question.',
        returnToTheBeginning: 'Go back to home page',
        sending: 'Sending...',
        error: 'Error:',
        retry: 'Retry',
        endDescription: 'Thanks!<br>You just helped<br />to make the world<br />closer together',
        recordId: 'Your answer has been saved with the following ID:',
        errorWhenSending: 'An error occured when sending the form.',
      },
    },
  },
  fr: {
    translation: {
      loading: 'Chargement...',
      layout: {
        cancelTitle: "Revenir à l'accueil ?",
        cancelText: "Voulez-vous vraiment revenir à l'accueil et perdre la session en cours ?",
        cancelConfirm: "Revenir à l'accueil",
        cancelCancel: 'Annuler',
        backToHomeIn: "Retour à l'accueil dans {{secondsBeforeFinish}}s",
        obsoleteBrowser: 'Votre navigateur n\'est pas supporté. Mettez-le à jour avant d\'utiliser cette application.',
        obsoleteBrowserMobile: 'Sur un smartphone, vous devriez utiliser le navigateur par défaut: Chrome pour Android, ou Safari pour iOS.',
        obsoleteBrowserDesktop: 'Sur un ordinateur, vous devriez utiliser le navigateur Chrome pour une meilleure expérience.',
      },
      videoList: {
        title: '<strong>Qui</strong> aimeriez-vous<br /><strong>écouter</strong> ?',
      },
      videoView: {
        chooseAnother: '<strong>Choisir</strong><br />une autre question',
        answer: '<strong>Répondre</strong><br />à cette question',
      },
      videoUploaderChoice: {
        useUploader: 'Utilisez le bouton ci-dessous pour envoyer une vidéo depuis votre appareil, ou pour utiliser l\'application photo de votre téléphone.',
        useUploaderButton: 'Envoyer une vidéo depuis mon appareil',
        useRecorder: 'Si votre navigateur est récent et à jour, vous pouvez enregistrer votre vidéo depuis l\'application.',
        useRecorderButton: 'Enregistrer une vidéo depuis l\'application',
        errorInChildren: 'Une erreur est survenue. Vous pouvez choisir un autre mode d\'envoi depuis les options.',
        videoDeviceChoice: 'Vous pouvez choisir la caméra à utiliser',
        audioDeviceChoice: 'Vous pouvez choisir le micro à utiliser',
        defaultChoice: 'Automatique',
      },
      videoUploader: {
        error: 'Une erreur est survenue lors de l\'envoi du fichier',
        errors: {
          'file-too-large': 'Le fichier est trop gros. La taille maximum autorisée est 200Mo.',
          'file-too-small': 'Le fichier est trop petit.',
          'too-many-files': 'Vous ne pouvez sélectionner qu\'un fichier',
          'file-invalid-type': 'Le type du fichier n\'est pas autorisé. Vous devez envoyer une vidéo au format mp4 ou webm.',
        },
      },
      videoRecorder: {
        begin: 'GO',
        start: 'START',
        stop: 'STOP',
        error: 'Erreur',
        useUploader: 'Si vous ne parvenez pas à utiliser votre webcam, cliquez sur le bouton ci-dessous pour envoyer directement une vidéo depuis votre ordinateur ou votre mobile.',
        cameraError: 'Erreur webcam : {{message}}',
        noUserMedia: 'Votre navigateur n\'a pas accès à votre webcam. {{message}}',
        noUserMediaHttp: 'Vous devez accéder au site en HTTPS',
        noUserMediaUnsupported: 'Vous devez utiliser un navigateur plus récent.',
        NotFoundError: 'Aucune webcam n\'a été trouvée',
        NotAllowedError: 'Vous devez autoriser l\'application à accéder à votre webcam',
        NotReadableError: 'L\'accès à votre webcam a été autorisé mais n\'est pas possible. Cela arrive généralement quand un autre logiciel utilise votre webcam en même temps. Fermez cette autre application et réessayez.',
        restartRecording: "<strong>Recommencer</strong><br />l'enregistrement",
        validateRecord: "<strong>Valider</strong><br />l'enregistrement",
      },
      videoAnswer: {
        answerQuestion: '<strong>Répondre</strong> à cette question:',
        answerFirstnameQuestion: 'Répondre à la<br />question de<br /><strong>{{firstname}}</strong>:',
        gotToEnd: "Pour que votre réponse soit validée, merci d'aller jusqu'à la dernière étape de l'app",
      },
      videoNewQuestion: {
        choiceTitle: '<strong>Merci !</strong>',
        choiceQuestion: 'Et vous, <br />quelle question<br />souhaitez-vous <br />poser au monde ?',
        askMyQuestion: '<strong>Poser</strong><br />ma question',
        skipMyQuestion: 'Je ne veux pas poser de question',
        askYourQuestion: '<strong>Posez</strong> votre<br /><strong>question</strong>:',
      },
      informationsForm: {
        title: 'Un dernier effort<br />pour valider <br />votre contribution.',
        subtitle: 'Sans cela, nous ne pourrons pas utiliser vos réponses, et ce serait bien dommage',
        firstname: 'Prénom',
        lastname: 'Nom',
        job: 'Profession',
        email: 'E-mail',
        birth: 'Date de naissance',
        country: 'Pays',
        cgu: "J'accepte de céder mes droits à l'image",
        cguLink: 'Lire l’article relatif au droit à l’image',
        newsletter: 'Je veux recevoir la newsletter',
        submit: '<strong>Finaliser</strong><br />ma réponse',
        badBirth: 'Le format de la date est invalide',
        closeCguText: 'Femer',
        cguText: `<p>
          En cochant la case « j’accepte de céder mes droits à l’image » j’autorise l’association 20 Questions to the World,
          représentée par son président Cyril Bruyelle et basée au 41 rue de lancry, 75010 PARIS,
          à reproduire et exploiter mon image ainsi que mes déclarations enregistrées sur la présente application à des fins de publication,
          de publicité ou toute autre activité médiatique incluant l’usage d’Internet. Cette autorisation comprend la possibilité
          pour l’association 20 Questions to the World d’apporter à la version initiale de mon image toutes modifications,
          adaptations ou suppressions qu’elle jugera utile.
        </p>
        <p>
          Je déclare avoir renoncé en pleine connaissance de cause à toute rétribution ou compensation, actuelle ou future,
          à l’utilisation du contenu de l’interview.
        </p>
        <p>
            Le présent accord inclus de façon non exhaustive :
        </p>
        <ul>
          <li>La permission de réaliser des productions vidéographiques utilisant l’image et/ou l’enregistrement audio de son signataire</li>
          <li>
            La permission d’utiliser, en partie ou en totalité, les films, photographies, enregistrements audio et reproductions résultant
            de l’enregistrement du signataire pour la création de publications, newsletter, magazines ou autre média digitaux sur internet,
            ainsi que de la projection lors d’événements grands public.
          </li>
        </ul>
        <p>
          Ce consentement est valable à perpétuité et ne nécessite aucune approbation préalable de son signataire.      
        </p>`,
      },
      sendForm: {
        errorEmptyForm: "Erreur : vous n'avez pas renseigné le formulaire ou répondu à la question.",
        returnToTheBeginning: 'Retourner au début',
        sending: 'Envoi en cours...',
        error: 'Erreur :',
        retry: 'Réessayer',
        endDescription: 'Merci !<br />Grâce à vous,<br />le monde<br />se parle<br />un peu plus.',
        recordId: "Votre réponse est enregistrée avec l'identifiant suivant :",
        errorWhenSending: "Une erreur est survenue lors de l'envoi.",
      },
    },
  },
};

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',

    whitelist: ['en', 'fr'],
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'br-portrait'],
    },
  });

const dateLocales = {
  en: enDates,
  fr: frDates,
};

const enLayout = {
  default: [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}', 'q w e r t y u i o p', "a s d f g h j k l '", 'z x c v b n m , . {shift}', '.com @ {space}',
  ],
  shift: [
    '~ ! # $ % ^ & * ( ) _ + {bksp}', 'Q W E R T Y U I O P', 'A S D F G H J K L "', 'Z X C V B N M < > {shift}', '.com @ {space}',
  ],
};

const frLayout = {
  default: [
    "& é \" ' ( - è _ ç à ) = {bksp}", 'a z e r t y u i o p {//}', 'q s d f g h j k l m ù', 'w x c v b n ? . : ! {shift}', '.com @ {space}',
  ],
  shift: [
    '1 2 3 4 5 6 7 8 9 0 ° + {bksp}', 'A Z E R T Y U I O P {//}', 'Q S D F G H J K L M µ', 'W X C V B N , ; / § {shift}', '.com @ {space}',
  ],
};

const keyboardLayouts = {
  en: enLayout,
  fr: frLayout,
};

const keyboardDisplays = {
  en: {
    '{bksp}': '← Delete',
    '{enter}': 'Enter',
    '{shift}': 'Shift',
    '{tab}': 'Tab',
    '{lock}': 'Shift Lock',
    '{space}': 'Space',
    '{//}': ' ',
  },
  fr: {
    '{bksp}': '← Effacer',
    '{enter}': 'Entrée',
    '{shift}': 'Maj',
    '{tab}': 'Tab',
    '{lock}': 'Verr. Maj',
    '{space}': 'Espace',
    '{//}': ' ',
  },
};

export const getDateLocale = () => dateLocales[i18n.language];

export const getCountriesList = () => countries[i18n.language];

export const getKeyboardLayout = () => keyboardLayouts[i18n.language];

export const getKeyboardDisplay = () => keyboardDisplays[i18n.language];

export default i18n;
