import { connect } from 'react-redux';
import { sendResetEvent, setLanguage } from '../actions';
import Homepage from '../../components/Homepage/Homepage';

const mapDispatchToProps = dispatch => ({
  setLanguage: value => dispatch(setLanguage(value)),
  onLoad: () => dispatch(sendResetEvent()),
});

const HomepageContainer = connect(
  undefined,
  mapDispatchToProps,
)(Homepage);

export default HomepageContainer;
