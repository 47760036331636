import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import VideoUploaderChoiceContainer from '../../store/containers/VideoUploaderChoiceContainer';
import './UserRecord.css';

const styles = {
  root: {
    paddingBottom: 10,
  },
};

class VideoAnswer extends Component {
  constructor(props) {
    super(props);

    this.onFinishRecording = this.onFinishRecording.bind(this);
  }

  onFinishRecording(videoBlobUrl, videoCameraFilename) {
    this.props.setVideoAnswer(this.props.video.id, videoBlobUrl, videoCameraFilename);
    this.props.history.push('/ask');
  }

  render() {
    const { video, previousVideoUrl } = this.props;

    if (!video) {
      return (
        <Typography variant="h1"><Trans i18nKey="loading" /></Typography>
      );
    }

    return (
      <div style={styles.root}>
        <div className="page-title">
          <Typography variant="h1">
            <Trans i18nKey={video.firstname ? 'videoAnswer.answerFirstnameQuestion' : 'videoAnswer.answerQuestion'} values={video} />
          </Typography>
        </div>
        <VideoUploaderChoiceContainer onValidate={this.onFinishRecording} defaultVideoUrl={previousVideoUrl} />
      </div>
    );
  }
}

VideoAnswer.defaultProps = {
  video: null,
  previousVideoUrl: null,
};

VideoAnswer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  video: PropTypes.shape(),
  setVideoAnswer: PropTypes.func.isRequired,
  previousVideoUrl: PropTypes.string,
};

export default withRouter(VideoAnswer);
