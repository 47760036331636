import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    position: 'relative',
    margin: '2vh 0',
    minHeight: 100,
    [theme.breakpoints.down('xs')]: {
      height: 240,
      marginLeft: '-10px',
      width: 'calc(100% + 20px)',
    },
    '@media (orientation: portrait) and (min-height: 800px)': {
      marginTop: '5vh',
    },
    '@media (orientation: portrait)': {
      marginLeft: '-2vw',
      width: 'calc(100% + 4vw)',
    },
  },
  sendFormRoot: {
    '@media (orientation: landscape)': {
      position: 'absolute',
      right: '10px',
      width: '100%',
      top: 'max(300px, 80vh)',
    },
  },
});

function ButtonsContainer({ children, classes, sendFormPage }) {
  return (
    <div className={`${classes.root} ${sendFormPage ? classes.sendFormRoot : ''}`}>
      {children}
    </div>
  );
}

ButtonsContainer.defaultProps = {
  children: <span />,
  sendFormPage: false,
};

ButtonsContainer.propTypes = {
  children: PropTypes.node,
  sendFormPage: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    sendFormRoot: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(ButtonsContainer);
