import { sendErrorToSentry } from '../helpers/helpers';

const axios = require('axios');

export default class DataClient {
  constructor(store) {
    this.store = store;
    this.store.subscribe(() => this.setApiHostFromStore());
    this.setApiHostFromStore();
  }

  setApiHostFromStore() {
    this.apiBaseUrl = this.store.getState().env.API_BASE_URL;
    this.assetsBaseUrl = this.store.getState().env.ASSETS_BASE_URL;
  }

  static handleError(error) {
    if (error.response?.data) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      // And there is an error message
      return Error(error.response.data.message || error.response.data);
    } if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      return Error(error.response.statusText || error.message);
    } if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest
      return Error(`Unable to find the server. ${error.message}`);
    }
    // Something happened in setting up the request that triggered an Error
    return Error(`Error when running the request. ${error.message}`);
  }

  static async fetch(uri, options = {}) {
    try {
      const response = await axios(uri, options);
      return response.data;
    } catch (error) {
      sendErrorToSentry(error);
      throw DataClient.handleError(error);
    }
  }

  refreshVideos() {
    return DataClient.fetch(`${this.assetsBaseUrl}/videos.json`);
  }

  sendForm(formData, sendFormProgressCallback) {
    const options = {
      method: 'POST',
      data: formData,
      onUploadProgress: sendFormProgressCallback,
    };

    return DataClient.fetch(`${this.apiBaseUrl}/sendform`, options);
  }

  reset() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/reset`, { method: 'POST' });
  }

  resetCameraRecording() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/record/reset`, { method: 'POST' });
  }

  detectCamera() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/detect`, { method: 'GET' });
  }

  startCameraPreview() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/preview/start`, { method: 'POST' });
  }

  stopCameraPreview() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/preview/stop`, { method: 'POST' });
  }

  startCameraRecording() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/record/start`, { method: 'POST' });
  }

  stopCameraRecording() {
    return DataClient.fetch(`${this.apiBaseUrl}/camera/record/stop`, { method: 'POST' }).then(data => data.movieName || '');
  }
}
