import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import VideoUploaderChoiceContainer from '../../store/containers/VideoUploaderChoiceContainer';
import './UserRecord.css';

const styles = () => ({
  root: {
    paddingBottom: 10,
  },
});

class VideoNewQuestion extends Component {
  constructor(props) {
    super(props);

    this.onFinishRecording = this.onFinishRecording.bind(this);
  }

  onFinishRecording(videoBlobUrl, videoCameraFilename) {
    this.props.setVideoQuestion(videoBlobUrl, videoCameraFilename);
    this.props.history.push('/informations');
  }

  render() {
    const { previousVideoUrl, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className="page-title">
          <Typography variant="h1">
            <Trans i18nKey="videoNewQuestion.askYourQuestion" />
          </Typography>
        </div>

        <VideoUploaderChoiceContainer onValidate={this.onFinishRecording} defaultVideoUrl={previousVideoUrl} />
      </div>
    );
  }
}

VideoNewQuestion.defaultProps = {
  previousVideoUrl: null,
};

VideoNewQuestion.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setVideoQuestion: PropTypes.func.isRequired,
  previousVideoUrl: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(VideoNewQuestion));
