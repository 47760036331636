import DateFnsUtils from '@date-io/date-fns';
import {
  Button, Modal, Paper, withStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import colors from '../../helpers/colors';
import { setStatePromise } from '../../helpers/helpers';
import {
  getCountriesList, getDateLocale, getKeyboardDisplay, getKeyboardLayout,
} from '../../i18n';
import './UserRecord.css';

const classesStyles = theme => ({
  gridLeft: {
    '@media (orientation: landscape) and (min-width: 1080px)': {
      maxWidth: '55%',
      flexBasis: '55%',
    },
  },
  gridLeftInner: {
    backgroundColor: colors.creme,
    padding: '0 2vh',
    '@media (orientation: landscape) and (min-width: 1080px)': {
      textAlign: 'right',
      paddingRight: '6vh',
      paddingTop: 60,
      paddingBottom: 100,
    },
  },
  gridRight: {
    '@media (orientation: landscape) and (min-width: 1080px)': {
      paddingBottom: 30,
      maxWidth: '40%',
      flexBasis: '40%',
    },
  },
  title: {
    padding: '2vh 0',
    fontWeight: '900',
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: 35,
    },
  },
  subtitle: {
    padding: '2vh 0',
    fontWeight: '600',
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: 20,
    },
  },
  form: {
    '@media (orientation: landscape) and (min-width: 1080px)': {
      borderLeft: `2px solid ${colors.greyLight}`,
      paddingLeft: 30,
    },
  },
  formGroup: {
    marginTop: '2vh',
    '@media (orientation: landscape) and (min-width: 1080px)': {
      marginTop: '1vh',
      borderBottomColor: colors.grey,
    },
  },
  label: {
    color: colors.grey,
    fontSize: '9vw',
    transform: 'translate(0, 30px) scale(1)',
    lineHeight: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: '1rem',
      top: -15,
    },
  },
  labelFocused: {
    transform: 'translate(0, 0) scale(1)',
    fontSize: '6vw',
    [theme.breakpoints.up(1080)]: {
      fontSize: '3rem',
    },
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: '.9rem',
      lineHeight: 0,
      top: 3,
    },
  },
  inputRoot: {
    '@media (orientation: landscape) and (min-width: 1080px)': {
      marginTop: '5px !important',
    },
  },
  input: {
    fontSize: '5.5vw',
    lineHeight: 1,
    [theme.breakpoints.up(1080)]: {
      fontSize: '3rem',
    },
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: '1rem',
      marginTop: 0,
    },
  },
  checkboxLabel: {
    color: colors.grey,
    fontSize: '4vw',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
    '@media (orientation: landscape) and (min-width: 1080px)': {
      fontSize: '.9rem',
    },
  },
  checkboxLabelChecked: {
    color: colors.red,
  },
  keyboardContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
  },
  hiddenKeyboardContainer: {
    bottom: -999,
    transition: 'bottom 1s',
  },
});

const StyledGrid = withStyles({
  container: {
    alignItems: 'initial',
    '@media (orientation: landscape) and (min-width: 1080px)': {
      justifyContent: 'space-between',
      padding: 0,
    },
  },
})(Grid);

const StyledFinishButton = withStyles(() => ({
  root: {
    boxShadow: 'none',
    padding: '20px 0',
    width: 300,
    maxWidth: '80%',
    minHeight: 100,
    fontSize: 21,
    margin: 'auto',
    marginTop: 50,
    display: 'block',
  },
  endIcon: {
    display: 'inline-block',
  },
}))(Button);

const styles = {
  buttonIcon: {
    fontSize: 40,
    marginLeft: 10,
  },
  buttonLabel: {
    lineHeight: 1.3,
    display: 'inline-block',
    textAlign: 'left',
    verticalAlign: 'super',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: '0 20px',
    maxWidth: 800,
    padding: '4vw',
    background: colors.greyLight,
    maxHeight: '90vh',
    overflowY: 'auto',
    textAlign: 'justify',
  },
  cguLink: {
    color: colors.grey,
    textDecoration: 'underline',
    marginLeft: 40,
  },
};

const defaultCountry = 'FR';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

class InformationsForm extends Component {
  static isFieldValid(field, value) {
    if (!InformationsForm.isRequiredField(field)) {
      return true;
    }

    if (field === 'email') {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(String(value).toLowerCase());
    }

    if (InformationsForm.isBooleanField(field)) {
      return value !== false;
    }

    return value !== '';
  }

  static isRequiredField(field) {
    return field !== 'newsletter';
  }

  static isBooleanField(field) {
    return field === 'cgu-accepted' || field === 'newsletter';
  }

  constructor(props) {
    super(props);

    this.fields = [
      'firstname',
      'lastname',
      'email',
      'birth',
      'country',
      'cgu-accepted',
      'newsletter',
    ];

    const state = {
      selectedInput: null,
      keyboardLayoutName: 'default',
      cguModalOpened: false,
    };

    const previousAnswers = props.previousAnswers || {};

    this.keyboardRef = null;
    this.inputsRefs = {};

    this.fields.forEach((field) => {
      state[field] = previousAnswers[field] || (InformationsForm.isBooleanField(field) ? false : '');
      state[`error-${field}`] = false;
      this.inputsRefs[field] = null;
    });

    this.state = state;
  }

  componentDidMount() {
    this.handleChange('country')({ target: { value: this.state.country || defaultCountry } }); // That's not cool
  }

  getAllFieldsErrors() {
    return Object.assign({}, ...this.fields.map(field => ({ [`error-${field}`]: !InformationsForm.isFieldValid(field, this.state[field]) })));
  }

  async setFormItemState(field, value) {
    await setStatePromise(this, {
      [field]: value,
      [`error-${field}`]: !InformationsForm.isFieldValid(field, value),
    });

    this.keyboardRef && this.keyboardRef.setInput(value, field);
  }

  handleChange(field) {
    return event => this.setFormItemState(field, event.target.value);
  }

  handleDateChange(field) {
    return date => {
      if (date instanceof Date && !Number.isNaN(date.getTime())) {
        this.setFormItemState(field, format(date, 'yyyy-MM-dd'));
      } else {
        this.setFormItemState(field, '');
      }
    };
  }

  handleChangeChecked(field) {
    return event => this.setFormItemState(field, event.target.checked);
  }

  isFormValid() {
    return Object.values(this.getAllFieldsErrors()).every(value => !value);
  }

  validateForm() {
    this.setState(this.getAllFieldsErrors(), () => {
      this.isFormValid() && this.saveForm();
    });
  }

  saveForm() {
    const answsers = Object.assign({}, ...this.fields.map(field => ({ [field]: this.state[field] })));
    this.props.setFormAnswers(answsers);
    this.props.history.push('/send');
  }

  async onKeyboardChange(input) {
    if (!this.state.selectedInput || !this.keyboardRef) {
      return;
    }

    const { caretPosition } = this.keyboardRef;

    await this.setFormItemState(this.state.selectedInput, input);

    if (caretPosition !== null) {
      InformationsForm.setInputCaretPosition(this.inputsRefs[this.state.selectedInput], caretPosition);
    }
  }

  static setInputCaretPosition(elem, pos) {
    if (elem && elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
    }
  }

  onKeyboardKeyPress(button) {
    if (button === '{shift}' || button === '{lock}') {
      const layout = this.state.keyboardLayoutName;
      this.setState({ keyboardLayoutName: layout === 'default' ? 'shift' : 'default' });
    }
  }

  onDateTouch = (e) => {
    if (!this.props.useVirtualKeyboard || e.target.tagName !== 'INPUT') {
      return;
    }
    // Get the calendar button
    const buttonEls = e.target.parentElement.getElementsByTagName('button');
    if (!buttonEls.length) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    buttonEls[0].click();
  }

  render() {
    const { classes, t } = this.props;
    const formGroupClasses = { root: classes.formGroup };
    const inputProps = { classes: { root: classes.inputRoot, input: classes.input }, inputProps: { spellCheck: 'off', autoCapitalize: 'on' } };
    const inputLabelProps = {
      classes: {
        shrink: classes.labelFocused, root: classes.label, focused: classes.labelFocused, filled: classes.labelFocused,
      },
    };
    const today = new Date();
    const countries = getCountriesList();

    return (
      <div style={{ position: 'relative' }}>
        {this.props.useVirtualKeyboard && (
          <div className={classes.keyboardContainer + (this.state.selectedInput ? '' : ` ${classes.hiddenKeyboardContainer}`)}>
            <Keyboard
              keyboardRef={r => (this.keyboardRef = r)}
              inputName={this.state.selectedInput}
              layout={getKeyboardLayout()}
              display={getKeyboardDisplay()}
              layoutName={this.state.keyboardLayoutName}
              onChange={input => this.onKeyboardChange(input)}
              onKeyPress={button => this.onKeyboardKeyPress(button)}
              preventMouseDownDefault
              physicalKeyboardHighlight={false}
              useTouchEvents={false}
              debug={false}
            />
          </div>
        )}

        <StyledGrid container justify="center" alignItems="center">
          <Grid item xs={12} className={classes.gridLeft}>
            <div className={`ignoreResponsivePaddingOnPortrait ${classes.gridLeftInner}`}>
              <Typography variant="h3" className={classes.title}><Trans i18nKey="informationsForm.title" /></Typography>
              <Typography variant="h4" className={classes.subtitle}><Trans i18nKey="informationsForm.subtitle" /></Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridRight} style={{ paddingTop: `${this.fields.length < 6 ? 5 : 0}vh` }}>

            <form noValidate autoComplete="off" className={classes.form}>
              {this.fields.includes('firstname') && (
              <TextField
                id="firstname"
                label={t('informationsForm.firstname')}
                value={this.state.firstname}
                error={this.state['error-firstname']}
                onChange={this.handleChange('firstname')}
                onFocus={() => this.setState({ selectedInput: 'firstname' })}
                onBlur={() => this.setState({ selectedInput: null })}
                margin="normal"
                fullWidth
                classes={formGroupClasses}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                autoComplete="randomlsldfndkljgnd"
                inputRef={r => (this.inputsRefs.firstname = r)}
              />
              )}
              {this.fields.includes('lastname') && (
              <TextField
                id="lastname"
                label={t('informationsForm.lastname')}
                value={this.state.lastname}
                error={this.state['error-lastname']}
                onChange={this.handleChange('lastname')}
                onFocus={() => this.setState({ selectedInput: 'lastname' })}
                onBlur={() => this.setState({ selectedInput: null })}
                margin="normal"
                fullWidth
                classes={formGroupClasses}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                autoComplete="randomsdifjoifjsduf"
                inputRef={r => (this.inputsRefs.lastname = r)}
              />
              )}
              {this.fields.includes('email') && (
              <TextField
                id="email"
                label={t('informationsForm.email')}
                type={this.props.useVirtualKeyboard ? 'text' : 'email'}
                value={this.state.email}
                error={this.state['error-email']}
                onChange={this.handleChange('email')}
                onFocus={() => this.setState({ selectedInput: 'email' })}
                onBlur={() => this.setState({ selectedInput: null })}
                margin="normal"
                fullWidth
                classes={formGroupClasses}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                autoComplete="randomslrjezrslkjdnfkljdns"
                inputRef={r => (this.inputsRefs.email = r)}
              />
              )}
              {this.fields.includes('birth') && (
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={getDateLocale()}>
                <KeyboardDatePicker
                  margin="normal"
                  fullWidth
                  maxDate={today}
                  openTo="year"
                  views={['year', 'month', 'date']}
                  label={t('informationsForm.birth')}
                  invalidDateMessage={t('informationsForm.badBirth')}
                  invalidLabel="sds"
                  value={this.state.birth || null}
                  autoOk
                  disableFuture
                  // format="d MMM yyyy"
                  format="dd MM yyyy"
                  error={this.state['error-birth']}
                  onChange={this.handleDateChange('birth')}
                  onTouchEnd={this.onDateTouch}
                  classes={formGroupClasses}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  orientation="portrait"
                />
              </MuiPickersUtilsProvider>
              )}

              {this.fields.includes('country') && (
              <TextField
                id="country"
                label={t('informationsForm.country')}
                defaultValue={this.state.country || defaultCountry}
                error={this.state['error-country']}
                onChange={this.handleChange('country')}
                margin="normal"
                fullWidth
                classes={formGroupClasses}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                select
                SelectProps={{
                  native: true,
                  className: 'InformationFormSelect',
                }}
              >
                {Object.keys(countries).map(code => (
                  <option key={code} value={code}>{countries[code]}</option>
                ))}
              </TextField>
              )}

              {this.fields.includes('cgu-accepted') && (
              <FormControl
                fullWidth
                classes={formGroupClasses}
                margin="normal"
                error={this.state['error-cgu-accepted']}
              >
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={(
                    <Switch
                      color="primary"
                      onChange={this.handleChangeChecked('cgu-accepted')}
                      checked={this.state['cgu-accepted']}
                      value="yes"
                    />
                  )}
                  label={
                    <span className={this.state['cgu-accepted'] ? classes.checkboxLabelChecked : ''}>{t('informationsForm.cgu')}</span>
                  }
                />
              </FormControl>
              )}
              {this.fields.includes('cgu-accepted') && (
              <Button variant="text" onClick={() => this.setState({ cguModalOpened: true })} style={styles.cguLink}>
                <Trans i18nKey="informationsForm.cguLink" />
              </Button>
              )}
              {this.fields.includes('newsletter') && (
              <FormControl
                fullWidth
                classes={formGroupClasses}
                margin="normal"
                error={this.state['error-newsletter']}
              >
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={(
                    <Switch
                      color="primary"
                      onChange={this.handleChangeChecked('newsletter')}
                      checked={this.state.newsletter || false}
                      value="yes"
                    />
                  )}
                  label={
                    <span className={this.state.newsletter ? classes.checkboxLabelChecked : ''}>{t('informationsForm.newsletter')}</span>
                  }
                />
              </FormControl>
              )}
            </form>

            <StyledFinishButton
              variant="contained"
              color="primary"
              onClick={() => this.validateForm()}
              disabled={!this.isFormValid()}
              endIcon={<DoneIcon style={styles.buttonIcon} />}
            >
              <span style={styles.buttonLabel}>
                <Trans i18nKey="informationsForm.submit" />
              </span>
            </StyledFinishButton>

          </Grid>
        </StyledGrid>

        <Modal
          open={this.state.cguModalOpened}
          onClose={() => this.setState({ cguModalOpened: false })}
          style={styles.modal}
        >
          <Paper elevation={3} style={styles.paper}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: t('informationsForm.cguText') }} />
            <Button variant="contained" onClick={() => this.setState({ cguModalOpened: false })} className="margin-v">
              <Trans i18nKey="informationsForm.closeCguText" />
            </Button>
          </Paper>
        </Modal>
      </div>
    );
  }
}

InformationsForm.defaultProps = {
  previousAnswers: {},
  useVirtualKeyboard: false,
};

InformationsForm.propTypes = {
  setFormAnswers: PropTypes.func.isRequired,
  previousAnswers: PropTypes.shape(),
  useVirtualKeyboard: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    formGroup: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    inputRoot: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelFocused: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string.isRequired,
    checkboxLabelChecked: PropTypes.string.isRequired,
    keyboardContainer: PropTypes.string.isRequired,
    hiddenKeyboardContainer: PropTypes.string.isRequired,
    gridLeft: PropTypes.string.isRequired,
    gridLeftInner: PropTypes.string.isRequired,
    gridRight: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(withStyles(classesStyles)(withTranslation()(InformationsForm)));
