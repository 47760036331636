import { container } from '../container';

export const cameraAction = {
  RESET: 'RESET',
  DETECT_CAMERA: 'DETECT_CAMERA',
  START_PREVIEW: 'START_PREVIEW',
  STOP_PREVIEW: 'STOP_PREVIEW',
  START_RECORDING: 'START_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  RESET_RECORDING: 'RESET_RECORDING',
};

let runningRequest = null;

export const runCameraRequest = (action) => {
  if (action === cameraAction.RESET) {
    return container.dataClient.reset();
  }
  if (action === cameraAction.DETECT_CAMERA) {
    return container.dataClient.detectCamera();
  }
  if (action === cameraAction.START_PREVIEW) {
    return container.dataClient.startCameraPreview();
  }
  if (action === cameraAction.STOP_PREVIEW) {
    return container.dataClient.stopCameraPreview();
  }
  if (action === cameraAction.START_RECORDING) {
    return container.dataClient.startCameraRecording();
  }
  if (action === cameraAction.STOP_RECORDING) {
    return container.dataClient.stopCameraRecording();
  }
  if (action === cameraAction.RESET_RECORDING) {
    return container.dataClient.resetCameraRecording();
  }

  return Promise.reject();
};

const parseResponseError = (e) => {
  const message = e.message || '';

  let errorPosition = message.indexOf('*** Error ***'); // gphoto2 error
  if (errorPosition >= 0) {
    const gphotoError = message.substr(errorPosition).split('\n')[1];
    return new Error(gphotoError || message);
  }

  errorPosition = message.indexOf('*** Error:'); // gphoto2 error
  if (errorPosition >= 0) {
    const gphotoError = message.substr(errorPosition);
    return new Error(gphotoError || message);
  }

  return e;
};

export const sendCameraRequest = async (action) => {
  if (runningRequest !== null) {
    await runningRequest;
  }

  runningRequest = runCameraRequest(action);

  try {
    const response = await runningRequest;
    runningRequest = null;
    return response;
  } catch (e) {
    const error = parseResponseError(e);
    runningRequest = null;
    throw error;
  }
};
