import { connect } from 'react-redux';
import VideoView from '../../components/VideoView/VideoView';
import { setStartTime } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  video: state.videos.find(v => v.id === ownProps.match.params.videoId),
});

const mapDispatchToProps = dispatch => ({
  setStartTime: () => dispatch(setStartTime()),
});

const VideoViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoView);

export default VideoViewContainer;
