import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import PreviousButton from '../BottomButtons/PreviousButton';
import facebookImg from './images/facebook.svg';
import instagramImg from './images/instagram.svg';
import youtubeImg from './images/youtube.svg';
import endLandscapeImg from './images/endLandscape.webp';
import endPortraitImg from './images/endPortrait.webp';
import colors from '../../helpers/colors';
import './UserRecord.css';

const styles = () => ({
  root: {
  },
  image: {
    minWidth: '100%',
    maxHeight: '60vh',
    margin: 0,
    '@media (orientation: landscape)': {
      maxHeight: '60vh',
      minHeight: 400,
    },
  },
  imagePortrait: {
    display: 'none',
    width: '100%',
    maxWidth: '100%',
    '@media (orientation: portrait)': {
      display: 'inline-block',
    },
  },
  imageLandscape: {
    display: 'none',
    '@media (orientation: landscape)': {
      display: 'inline-block',
    },
  },
  imageText: {
    position: 'absolute',
    left: '60%',
    top: '12vh',
    color: colors.white,
    fontWeight: 'bold',
    '@media (orientation: portrait)': {
      top: '4vh',
    },
  },
  socialImgRoot: {
    textAlign: 'right',
    marginTop: '1vh',
  },
  socialImg: {
    height: 30,
    marginRight: '1%',
    marginBottom: -5,
    '@media (orientation: portrait) and (max-width: 600px)': {
      height: 20,
      marginBottom: 0,
    },
  },
  circularProgress: {
    marginLeft: 20,
    marginRight: 20,
  },
  brand: {
    fontSize: 'min(4vh, 4vw)',
  },
  recordId: {
    fontSize: 10,
  },
});

const StyledGrid = withStyles({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
})(Grid);

const IDLE_TIMEOUT = 15;

class SendForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsBeforeFinish: IDLE_TIMEOUT,
      finishTimeoutId: null,
    };
  }

  componentDidMount() {
    if (this.props.userHasAnswered) {
      this.triggerSend();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.sendFormStatus.isFinished && !prevProps.sendFormStatus.isFinished && this.props.useIdleTimer) {
      this.setFinishInterval(this.state.secondsBeforeFinish);
    }
  }

  componentWillUnmount() {
    if (this.state.finishTimeoutId !== null) {
      clearTimeout(this.state.finishTimeoutId);
    }
  }

  setFinishInterval(seconds) {
    const secondsBeforeFinish = parseInt(seconds || 0, 10);

    if (secondsBeforeFinish <= 0) {
      this.finish();
      return;
    }

    const finishTimeoutId = setTimeout(() => this.setFinishInterval(secondsBeforeFinish - 1), 1000);
    this.setState({ secondsBeforeFinish, finishTimeoutId });
  }

  triggerSend() {
    this.props.sendFormAnswers();
  }

  finish() {
    this.props.resetUserRecord();
    this.props.history.push('/');
  }

  render() {
    const { userHasAnswered, history, classes } = this.props;
    const status = this.props.sendFormStatus;

    if (!userHasAnswered) {
      return (
        <>
          <Typography variant="h4" className="margin-v-large"><Trans i18nKey="sendForm.errorEmptyForm" /></Typography>
          <ButtonsContainer>
            <PreviousButton onClick={() => history.push('/')}>
              <strong><Trans i18nKey="sendForm.returnToTheBeginning" /></strong>
            </PreviousButton>
          </ButtonsContainer>
        </>
      );
    }

    if (typeof status.isFinished === 'undefined' || status.isSending) {
      return (
        <>
          <Typography variant="h2" className="margin-v-large">
            <CircularProgress size={30} className={classes.circularProgress} />
            <Trans i18nKey="sendForm.sending" />
            {status.progress !== undefined && <span> ({ status.progress }%)</span>}
          </Typography>
        </>
      );
    }

    if (typeof status.error === 'string' || typeof status.error?.message !== 'undefined') {
      return (
        <>
          <Typography variant="h2" className="margin-v-large">
            <Trans i18nKey="sendForm.error" /> {status.error.message || status.error}
          </Typography>
          <ButtonsContainer>
            <NextButton onClick={() => this.triggerSend()}>
              <strong><Trans i18nKey="sendForm.retry" /></strong>
            </NextButton>
          </ButtonsContainer>
        </>
      );
    }

    if (status.isFinished) {
      return (
        <div className={classes.root}>
          <StyledGrid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.imageText}><Trans i18nKey="sendForm.endDescription" /></Typography>

              <img src={endLandscapeImg} className={`${classes.image} ${classes.imageLandscape}`} alt="" />
              <img src={endPortraitImg} className={`${classes.image} ${classes.imagePortrait}`} alt="" />
            </Grid>
            <Grid item xs={12}>
              {false && status.data && status.data.uploadName && (
                <>
                  <Typography gutterBottom className={classes.recordId}><em><Trans i18nKey="sendForm.recordId" /> {status.data.uploadName}.</em></Typography>
                </>
              )}

              <Typography variant="h5" gutterBottom className={classes.socialImgRoot}>
                <a href="https://www.instagram.com/20questionstotheworld/" target="_blank" rel="noreferrer">
                  <img src={instagramImg} alt="instagram" className={classes.socialImg} />
                </a>
                <a href="https://www.facebook.com/20questionstotheworld" target="_blank" rel="noreferrer">
                  <img src={facebookImg} alt="facebook" className={classes.socialImg} />
                </a>
                <a href="https://www.youtube.com/channel/UCVxjZOlPyCdvKZ05InPdcTA" target="_blank" rel="noreferrer">
                  <img src={youtubeImg} alt="youtube" className={classes.socialImg} />
                </a>
                @20questionstotheworld
              </Typography>
            </Grid>
          </StyledGrid>

          <ButtonsContainer sendFormPage>
            <NextButton onClick={() => this.finish()}>
              <strong>
                <Trans i18nKey="sendForm.returnToTheBeginning" />
                {this.state.secondsBeforeFinish !== IDLE_TIMEOUT && (
                  <>({this.state.secondsBeforeFinish})</>
                )}
              </strong>
            </NextButton>
          </ButtonsContainer>
        </div>
      );
    }

    return (
      <>
        <Typography variant="h2" className="margin-v-large"><Trans i18nKey="sendForm.errorWhenSending" /></Typography>
        <Button variant="contained" color="secondary" size="large" onClick={() => this.triggerSend()}><Trans i18nKey="sendForm.retry" /></Button>
      </>
    );
  }
}

SendForm.defaultProps = {
  sendFormStatus: {},
  useIdleTimer: false,
};

SendForm.propTypes = {
  useIdleTimer: PropTypes.bool,
  userHasAnswered: PropTypes.bool.isRequired,
  sendFormStatus: PropTypes.shape({
    isSending: PropTypes.bool,
    progress: PropTypes.number,
    isFinished: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    data: PropTypes.shape({
      uploadName: PropTypes.string,
    }),
  }),
  sendFormAnswers: PropTypes.func.isRequired,
  resetUserRecord: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imagePortrait: PropTypes.string.isRequired,
    imageLandscape: PropTypes.string.isRequired,
    socialImgRoot: PropTypes.string.isRequired,
    socialImg: PropTypes.string.isRequired,
    circularProgress: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(SendForm));
