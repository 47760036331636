import React from 'react';
import { Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import supportedBrowsers from '../../supportedBrowsers';
import colors from '../../helpers/colors';
import { mobileDetect } from '../../helpers/helpers';

const styles = {
  alert: {
    width: '100%',
    background: colors.red,
    color: colors.white,
    padding: 10,
    textAlign: 'center',
    zIndex: 1,
  },
};

function UnsupportedBrowser() {
  if (supportedBrowsers.test(navigator.userAgent)) {
    return null;
  }

  return (
    <section style={styles.alert}>
      <Typography variant="body2"><Trans i18nKey="layout.obsoleteBrowser" /></Typography>
      {!!mobileDetect.mobile() && <Typography variant="body2"><Trans i18nKey="layout.obsoleteBrowserMobile" /></Typography>}
      {!mobileDetect.mobile() && <Typography variant="body2"><Trans i18nKey="layout.obsoleteBrowserDesktop" /></Typography>}
    </section>
  );
}

export default React.memo(UnsupportedBrowser);
