import { connect } from 'react-redux';
import About from '../../components/About/About';
import {
  setClientName,
  setAudioBitsPerSecond,
  setAudioDeviceId,
  setSendCameraCommands,
  setUseIdleTimer,
  setUseVirtualKeyboard,
  setVideoBitsPerSecond,
  setVideoRecorderChoice,
  setVideoDeviceId,
  setVideoIdealWidth,
  setVideoMimeType,
  setVideoTransform,
  setLastMediaPermissionAllowDate,
} from '../actions';

const mapStateToProps = state => ({
  env: state.env,
  config: state.config,
});

const mapDispatchToProps = dispatch => ({
  setClientName: value => dispatch(setClientName(value)),
  setVideoRecorderChoice: value => dispatch(setVideoRecorderChoice(value)),
  setVideoDeviceId: id => dispatch(setVideoDeviceId(id)),
  setAudioDeviceId: id => dispatch(setAudioDeviceId(id)),
  setVideoBitsPerSecond: value => dispatch(setVideoBitsPerSecond(value)),
  setAudioBitsPerSecond: value => dispatch(setAudioBitsPerSecond(value)),
  setVideoMimeType: value => dispatch(setVideoMimeType(value)),
  setVideoIdealWidth: value => dispatch(setVideoIdealWidth(value)),
  setVideoTransform: value => dispatch(setVideoTransform(value)),
  setSendCameraCommands: value => dispatch(setSendCameraCommands(value)),
  setUseVirtualKeyboard: value => dispatch(setUseVirtualKeyboard(value)),
  setUseIdleTimer: value => dispatch(setUseIdleTimer(value)),
  setLastMediaPermissionAllowDate: () => dispatch(setLastMediaPermissionAllowDate()),
});

const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(About);

export default AboutContainer;
