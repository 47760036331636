import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { commontButtonStyle } from './PreviousButton';

const StyledRightButton = withStyles(theme => ({
  root: {
    ...commontButtonStyle(theme),
    fontSize: 'min(3vw, 2rem)',
    float: 'right',
    paddingLeft: 10,
    paddingRight: 20,
    textAlign: 'right',
    marginLeft: '50%',
    marginTop: 10,
    '@media (orientation: landscape)': {
      fontSize: 'min(1.8vh, 2rem)',
      maxWidth: '350px',
    },
  },
  label: {
    display: 'block',
  },
}))(Button);

function NextButtonSecondary({ onClick, disabled, children }) {
  const handleClick = (e) => {
    if (!onClick) {
      return;
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <StyledRightButton color="secondary" size="large" onClick={handleClick} disabled={disabled}>
      {children}
    </StyledRightButton>
  );
}

NextButtonSecondary.defaultProps = {
  onClick: null,
  disabled: false,
  children: <span />,
};

NextButtonSecondary.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default NextButtonSecondary;
