const config = (state = '', action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return { ...state, language: action.value };
    case 'SET_CLIENT_NAME':
      return { ...state, clientName: action.value };
    case 'SET_VIDEO_RECORDER_CHOICE':
      return { ...state, videoRecorderChoice: action.value };
    case 'SET_VIDEO_DEVICE_ID':
      return { ...state, videoDeviceId: action.id };
    case 'SET_AUDIO_DEVICE_ID':
      return { ...state, audioDeviceId: action.id };
    case 'SET_VIDEO_BITS_PER_SECOND':
      return { ...state, videoBitsPerSecond: action.value };
    case 'SET_AUDIO_BITS_PER_SECOND':
      return { ...state, audioBitsPerSecond: action.value };
    case 'SET_VIDEO_MIME_TYPE':
      return { ...state, videoMimeType: action.value };
    case 'SET_VIDEO_IDEAL_WIDTH':
      return { ...state, videoIdealWidth: action.value };
    case 'SET_VIDEO_TRANSFORM':
      return { ...state, videoTransform: action.value };
    case 'SET_SEND_CAMERA_COMMANDS':
      return { ...state, sendCameraCommands: action.value };
    case 'SET_USE_VIRTUAL_KEYBOARD':
      return { ...state, useVirtualKeyboard: action.value };
    case 'SET_USE_IDLE_TIMER':
      return { ...state, useIdleTimer: action.value };
    default:
      return state;
  }
};

export default config;
