import i18n from 'i18next';
import { container } from '../../container';
import { cameraAction, sendCameraRequest } from '../../helpers/cameraClient';
import { addVideoAssets, clearBlobStorage, persistBlobUrl } from '../../helpers/helpers';
import { addOrUpdateVideo, setSendFormProgress, setSendFormStatus } from './index';
import { getFormDataFromUserRecord } from '../reducers/userRecord';
import { getClientLocales } from '../../helpers/clientsHelper';

export const createDataActionsMiddleware = store => next => async (action) => {
  next(action);

  if (action.type === 'RESET_ALL' && store.getState().config.sendCameraCommands) {
    sendCameraRequest(cameraAction.RESET).catch(() => {});
  }

  if (action.type === 'REFRESH_VIDEOS_REQUEST') {
    container.dataClient.refreshVideos().then((data) => {
      Object.keys(data).forEach((index) => {
        store.dispatch(addOrUpdateVideo(data[index].id || `video-${+index}`, data[index]));
      });
    });
  }

  if (action.type === 'SEND_FORM_ANSWERS') {
    store.dispatch(setSendFormStatus({ isSending: true }));

    const formData = await getFormDataFromUserRecord(store.getState().userRecord);

    const sendFormProgressCallback = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        store.dispatch(setSendFormProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
      }
    };

    container.dataClient.sendForm(formData, sendFormProgressCallback).then((data) => {
      store.dispatch(setSendFormStatus({ isFinished: true, data }));
    }).catch((error) => {
      store.dispatch(setSendFormStatus({ isFinished: true, error }));
    });
  }
};

export const createUserRecordMiddleware = store => next => async (action) => {
  next(action);

  if (action.type === 'SET_VIDEO_ANSWER' || action.type === 'SET_VIDEO_QUESTION') {
    // Store the video in indexeddb
    persistBlobUrl(action.videoBlobUrl);
  }

  if (action.type === 'RESET_USER_RECORD') {
    try {
      const { userRecord } = store.getState();
      userRecord?.answerVideoBlobUrl && URL.revokeObjectURL(userRecord.answerVideoBlobUrl);
      userRecord?.questionVideoBlobUrl && URL.revokeObjectURL(userRecord.questionVideoBlobUrl);
    } catch {
      // Okay.
    }
    clearBlobStorage();
  }
};

export const createAddOrUpdateVideoMiddleware = store => next => (action) => {
  if (action.type === 'ADD_OR_UPDATE_VIDEO' || action.type === 'ADD_VIDEO' || action.type === 'UPDATE_VIDEO') {
    action.video = addVideoAssets(store, action.video);
  }

  return next(action);
};

export const createSetLanguageMiddleware = store => next => (action) => { // eslint-disable-line no-unused-vars
  if (action.type === 'SET_LANGUAGE') {
    i18n.changeLanguage(action.value);
  }

  if (action.type === 'SET_CLIENT_NAME') {
    const languages = getClientLocales(action.payload);
    if (languages.length === 1) {
      i18n.changeLanguage(languages[0]);
    }
    if (!languages.includes(i18n.language)) {
      i18n.changeLanguage(languages[0]);
    }
  }

  if (action.type === 'persist/REHYDRATE' && action.payload && action.payload.config && action.payload.config.language) {
    i18n.changeLanguage(action.payload.config.language);
  }

  return next(action);
};

const getMiddlewares = [
  createDataActionsMiddleware,
  createUserRecordMiddleware,
  createAddOrUpdateVideoMiddleware,
  createSetLanguageMiddleware,
];

export default getMiddlewares;
