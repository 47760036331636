import { connect } from 'react-redux';
import VideoAnswer from '../../components/UserRecord/VideoAnswer';
import { setVideoAnswer } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  video: state.videos.find(v => v.id === ownProps.match.params.videoId),
  previousVideoUrl: state.userRecord.answerVideoBlobUrl,
});

const mapDispatchToProps = dispatch => ({
  setVideoAnswer: (questionId, videoBlobUrl, videoCameraFilename) => dispatch(setVideoAnswer(questionId, videoBlobUrl, videoCameraFilename)),
});

const VideoAnswerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoAnswer);

export default VideoAnswerContainer;
