import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const RouteChangeListener = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.NODE_ENV !== 'dev') {
      console.info('Route changed', location.pathname);
    }
  }, [location]);

  return null;
};

export default RouteChangeListener;
