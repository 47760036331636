export const addOrUpdateVideo = (id, video) => ({
  type: 'ADD_OR_UPDATE_VIDEO',
  id,
  video,
});

export const refreshVideosRequest = () => ({
  type: 'REFRESH_VIDEOS_REQUEST',
});

export const setVideoAnswer = (questionId, videoBlobUrl, videoCameraFilename) => ({
  type: 'SET_VIDEO_ANSWER',
  questionId,
  videoBlobUrl,
  videoCameraFilename,
});

export const setVideoQuestion = (videoBlobUrl, videoCameraFilename) => ({
  type: 'SET_VIDEO_QUESTION',
  videoBlobUrl,
  videoCameraFilename,
});

export const setFormAnswers = answers => ({
  type: 'SET_FORM_ANSWERS',
  answers,
});

export const sendFormAnswers = () => ({
  type: 'SEND_FORM_ANSWERS',
});

export const setSendFormProgress = progress => ({
  type: 'SET_SEND_FORM_PROGRESS',
  progress,
});

export const setSendFormStatus = status => ({
  type: 'SET_SEND_FORM_STATUS',
  status,
});

export const setStartTime = status => ({
  type: 'SET_START_TIME',
  status,
});

export const sendResetEvent = () => ({
  type: 'RESET_ALL',
});

export const resetUserRecord = () => ({
  type: 'RESET_USER_RECORD',
});

export const setLanguage = value => ({
  type: 'SET_LANGUAGE',
  value,
});

export const setClientName = value => ({
  type: 'SET_CLIENT_NAME',
  value,
});

export const setLastMediaPermissionAllowDate = value => ({
  type: 'SET_LAST_MEDIA_PERMISSION_ALLOW_DATE',
  value: value || new Date(),
});

export const setVideoRecorderChoice = value => ({
  type: 'SET_VIDEO_RECORDER_CHOICE',
  value,
});

export const setVideoDeviceId = id => ({
  type: 'SET_VIDEO_DEVICE_ID',
  id,
});

export const setAudioDeviceId = id => ({
  type: 'SET_AUDIO_DEVICE_ID',
  id,
});
export const setVideoBitsPerSecond = value => ({
  type: 'SET_VIDEO_BITS_PER_SECOND',
  value,
});

export const setAudioBitsPerSecond = value => ({
  type: 'SET_AUDIO_BITS_PER_SECOND',
  value,
});

export const setVideoMimeType = value => ({
  type: 'SET_VIDEO_MIME_TYPE',
  value,
});

export const setVideoIdealWidth = value => ({
  type: 'SET_VIDEO_IDEAL_WIDTH',
  value,
});

export const setVideoTransform = value => ({
  type: 'SET_VIDEO_TRANSFORM',
  value,
});

export const setSendCameraCommands = value => ({
  type: 'SET_SEND_CAMERA_COMMANDS',
  value,
});

export const setUseVirtualKeyboard = value => ({
  type: 'SET_USE_VIRTUAL_KEYBOARD',
  value,
});

export const setUseIdleTimer = value => ({
  type: 'SET_USE_IDLE_TIMER',
  value,
});
