import { connect } from 'react-redux';
import { setFormAnswers } from '../actions';
import InformationsForm from '../../components/UserRecord/InformationsForm';

const mapStateToProps = state => ({
  previousAnswers: state.userRecord.formAnswers,
  useVirtualKeyboard: state.config.useVirtualKeyboard,
});

const mapDispatchToProps = dispatch => ({
  setFormAnswers: answers => dispatch(setFormAnswers(answers)),
});

const InformationsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InformationsForm);

export default InformationsFormContainer;
