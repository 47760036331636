export const getClientLocales = () => ['fr', 'en'];

export const setDefaultStoreDataForClient = (defaultStoreData) => {
  const clientLanguages = getClientLocales(defaultStoreData.config.clientName);
  if (clientLanguages.length === 1) {
    defaultStoreData.config.language = clientLanguages[0];
  }

  return defaultStoreData;
};
