import { connect } from 'react-redux';
import {
  setAudioDeviceId, setLastMediaPermissionAllowDate, setVideoDeviceId, setVideoRecorderChoice,
} from '../actions';
import VideoUploaderChoice from '../../components/VideoUploaderChoice/VideoUploaderChoice';

const mapStateToProps = (state) => ({
  config: state.config,
  lastMediaPermissionAllowDate: state.app.lastMediaPermissionAllowDate,
});

const mapDispatchToProps = dispatch => ({
  setLastMediaPermissionAllowDate: value => dispatch(setLastMediaPermissionAllowDate(value)),
  setVideoRecorderChoice: value => dispatch(setVideoRecorderChoice(value)),
  setVideoDeviceId: value => dispatch(setVideoDeviceId(value)),
  setAudioDeviceId: value => dispatch(setAudioDeviceId(value)),
});

const VideoUploaderChoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoUploaderChoice);

export default VideoUploaderChoiceContainer;
