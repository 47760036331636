import Typography from '@material-ui/core/Typography/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  BigPlayButton, ControlBar, CurrentTimeDisplay, DurationDisplay, Player, PlayToggle, ProgressControl, TimeDivider,
} from 'video-react';
import 'video-react/dist/video-react.css';
import { withStyles } from '@material-ui/core/styles';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import PreviousButton from '../BottomButtons/PreviousButton';
import { isApple, mobileDetect } from '../../helpers/helpers';
import './VideoView.css';

const styles = () => ({
  content: {
    paddingBottom: 10,
    position: 'relative',
    '@media (orientation: portrait)': {
      paddingTop: 40,
    },
  },
  countryLabel: {
    fontSize: '0.65em',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
});

class VideoView extends Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.subscribedToPlayerEvents = false;
    this.state = {
      videoHasStarted: false,
      videoHasEnded: false,
      videoIsPaused: false,
    };
  }

  componentDidMount() {
    this.subscribeToPlayerEvents();
    this.props.setStartTime();
  }

  componentDidUpdate() {
    this.subscribeToPlayerEvents();
  }

  subscribeToPlayerEvents() {
    if (this.subscribedToPlayerEvents || !this.playerRef.current) {
      return;
    }

    this.playerRef.current.subscribeToStateChange(this.handleStateChange.bind(this));
    this.subscribedToPlayerEvents = true;
  }

  handleStateChange(state) {
    document.dispatchEvent(new Event('video')); // Send event for idle timer reset

    if (state.hasStarted && !this.state.videoHasStarted) {
      this.setState({ videoHasStarted: true });
    }

    if (state.ended) {
      this.setState({ videoHasEnded: true });
      this.playerRef.current.load();
      return;
    }

    if (state.paused !== this.state.videoIsPaused) {
      this.setState({ videoIsPaused: state.paused });
    }
  }

  render() {
    const { video, history, classes } = this.props;

    if (!video) {
      return (
        <Typography variant="subtitle1"><Trans i18nKey="loading" /></Typography>
      );
    }

    const { videoHasStarted, videoHasEnded, videoIsPaused } = this.state;
    const videoIsFinished = videoHasStarted && videoHasEnded && videoIsPaused;
    const isMobile = !!mobileDetect.mobile();
    // See https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#new-behaviors for autoPlay rules on Chrome

    return (
      <div className={classes.content + (videoIsFinished ? ' video-finished' : ' video-not-finished')}>
        <Player
          playsInline
          poster={video.urls.thumb}
          preload="auto"
          aspectRatio="auto"
          autoPlay={!isMobile && !videoHasStarted}
          ref={this.playerRef}
        >
          <ControlBar
            autoHide
            disableCompletely={!videoHasEnded}
            disableDefaultControls
          >
            <PlayToggle />
            <CurrentTimeDisplay />
            <TimeDivider />
            <DurationDisplay />
            <ProgressControl />
          </ControlBar>
          <BigPlayButton position="center" />
          {!isApple && <source src={video.urls.webm} type="video/webm" />}
          <source src={video.urls.mp4} type="video/mp4" />
        </Player>

        {videoIsFinished || (
        <Typography variant="h2" className="margin-v">
          <strong>{video.firstname}</strong>
          <span className="small-square" />
          <small className={classes.countryLabel}>{video.location}</small>
        </Typography>
        )}

        {videoIsFinished && (
        <ButtonsContainer>
          <PreviousButton onClick={() => history.push('/videos')}>
            <Trans i18nKey="videoView.chooseAnother" />
          </PreviousButton>
          <NextButton onClick={() => history.push(`/videos/${video.id}/answer`)}>
            <Trans i18nKey="videoView.answer" />
          </NextButton>
        </ButtonsContainer>
        )}
      </div>
    );
  }
}

VideoView.defaultProps = {
  video: null,
};

VideoView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      thumb: PropTypes.string.isRequired,
      mp4: PropTypes.string.isRequired,
      webm: PropTypes.string.isRequired,
    }).isRequired,
    firstname: PropTypes.string,
    location: PropTypes.string,
  }),
  setStartTime: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    countryLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(VideoView));
