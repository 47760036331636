import { connect } from 'react-redux';
import { setVideoQuestion } from '../actions';
import AskNewQuestion from '../../components/UserRecord/AskNewQuestion';

const mapDispatchToProps = dispatch => ({
  resetVideoQuestion: () => dispatch(setVideoQuestion(null)),
});

const AskNewQuestionContainer = connect(
  undefined,
  mapDispatchToProps,
)(AskNewQuestion);

export default AskNewQuestionContainer;
