import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React from 'react';
import { commontButtonStyle } from './PreviousButton';

const styles = {
  icon: {
    fontSize: 50,
    height: 60,
    position: 'absolute',
    right: 0,
    top: 'calc( 50% - 30px )',
  },
};

const StyledRightButton = withStyles(theme => ({
  root: {
    ...commontButtonStyle(theme),
    float: 'right',
    paddingRight: 60,
    textAlign: 'right',
  },
  label: {
    display: 'block',
  },
}))(Button);

function NextButton({
  onClick, disabled, children, className,
}) {
  const handleClick = (e) => {
    if (!onClick) {
      return;
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <StyledRightButton color="primary" size="large" variant="contained" onClick={handleClick} disabled={disabled} className={className}>
      <KeyboardArrowRightIcon style={styles.icon} />
      {children}
    </StyledRightButton>
  );
}

NextButton.defaultProps = {
  onClick: null,
  disabled: false,
  children: <span />,
  className: '',
};

NextButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default NextButton;
