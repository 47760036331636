import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import './index.css';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import Layout from './components/Layout/Layout';
import NotFound from './components/NotFound/NotFound';
import VideosListContainer from './store/containers/VideosListContainer';
import VideoViewContainer from './store/containers/VideoViewContainer';
import VideoAnswerContainer from './store/containers/VideoAnswerContainer';
import VideoNewQuestionContainer from './store/containers/VideoNewQuestionContainer';
import InformationsFormContainer from './store/containers/InformationsFormContainer';
import SendFormContainer from './store/containers/SendFormContainer';
import AboutContainer from './store/containers/AboutContainer';
import LayoutWithTitleContainer from './store/containers/LayoutWithTitleContainer';
import HomepageContainer from './store/containers/HomepageContainer';
import UnsupportedBrowser from './components/Layout/UnsupportedBrowser';
import AskNewQuestionContainer from './store/containers/AskNewQuestionContainer';
import RouteChangeListener from './components/Layout/RouteChangeListener';

const RouteWithDefaultLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <LayoutWithTitleContainer>
        <Component {...matchProps} />
      </LayoutWithTitleContainer>
    )}
  />
);

RouteWithDefaultLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
};

function ErrorComponent({
  error, eventId,
}) {
  return (
    <div className="global-error">
      <p>
        [{error.name || 'Error'}] {error.message || 'An error occured'}<br /><br />
        <em>ErrorID: {eventId}</em>
      </p>
      <p>
        <button type="button" onClick={() => window.location.reload()}>Reload the page</button>
      </p>
    </div>
  );
}

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
};

function onError(error, componentStack, eventId) {
  Sentry.captureException({ eventId, error, componentStack });
}

export default function bootstrap(store, persistor) {
  ReactDOM.render(
    <Sentry.ErrorBoundary onError={onError} fallback={ErrorComponent} showDialog>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <RouteChangeListener />
            <Layout>
              <UnsupportedBrowser />
              <Switch>
                <Route exact path="/" component={HomepageContainer} />
                <RouteWithDefaultLayout exact path="/videos" component={VideosListContainer} />
                <RouteWithDefaultLayout exact path="/videos/:videoId" component={VideoViewContainer} />
                <RouteWithDefaultLayout exact path="/videos/:videoId/answer" component={VideoAnswerContainer} />
                <RouteWithDefaultLayout exact path="/ask" component={AskNewQuestionContainer} />
                <RouteWithDefaultLayout exact path="/record" component={VideoNewQuestionContainer} />
                <RouteWithDefaultLayout exact path="/informations" component={InformationsFormContainer} />
                <RouteWithDefaultLayout exact path="/send" component={SendFormContainer} />
                <RouteWithDefaultLayout exact path="/about" component={AboutContainer} />
                <RouteWithDefaultLayout component={NotFound} />
              </Switch>
            </Layout>
          </Router>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>, document.getElementById('root'),
  );
}
