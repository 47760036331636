Date.prototype.toJSON = function () { // eslint-disable-line no-extend-native, func-names
  const timezoneOffsetInHours = -(this.getTimezoneOffset() / 60);
  const sign = timezoneOffsetInHours >= 0 ? '+' : '-';
  const leadingZero = (timezoneOffsetInHours < 10) ? '0' : '';
  const correctedDate = new Date(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds());
  correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
  const iso = correctedDate.toISOString().replace('Z', '');

  return `${iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString()}:00`;
};

export function calcDuration(startDate, endDate) { // eslint-disable-line import/prefer-default-export
  const secs = (endDate.getTime() - startDate.getTime()) / 1000;

  const hr = Math.floor(secs / 3600);
  let min = Math.floor((secs - (hr * 3600)) / 60);
  let sec = Math.floor(secs - (hr * 3600) - (min * 60));

  if (min < 10) {
    min = `0${min}`;
  }

  if (sec < 10) {
    sec = `0${sec}`;
  }

  if (hr <= 0) {
    return `${min}:${sec}`;
  }

  return `${hr}:${min}:${sec}`;
}
