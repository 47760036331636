/**
 * Used to inject services in store middlewares.
 * TODO Find another way
 *
 * @typedef {Object} Container
 * @property {Store} store The name of the person.
 * @property {DataClient} dataClient The age of the person.
 */

/**
 * @type {Container} container
 */
export const container = {
  store: null,
  dataClient: null,
};

export default container;
