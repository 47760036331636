import { fetchBlobByUrl } from '../../helpers/helpers';

export const userRecordDefaultState = () => ({
  startTime: null,
  endTime: null,
  questionId: null,
  answerVideoBlobUrl: null,
  answerVideoCameraFilename: null,
  questionVideoBlobUrl: null,
  questionVideoCameraFilename: null,
  formAnswers: {},
  sendFormStatus: {},
});

const userRecord = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_USER_RECORD':
      return { ...state, ...userRecordDefaultState() };
    case 'SET_START_TIME':
      return { ...state, startTime: new Date() };
    case 'SET_VIDEO_ANSWER':
      return {
        ...state, questionId: action.questionId, answerVideoBlobUrl: action.videoBlobUrl, answerVideoCameraFilename: action.videoCameraFilename,
      };
    case 'SET_VIDEO_QUESTION':
      return { ...state, questionVideoBlobUrl: action.videoBlobUrl, questionVideoCameraFilename: action.videoCameraFilename };
    case 'SET_FORM_ANSWERS':
      return { ...state, formAnswers: action.answers, endTime: new Date() };
    case 'SET_SEND_FORM_STATUS':
      return { ...state, sendFormStatus: { ...action.status, progress: undefined } };
    case 'SET_SEND_FORM_PROGRESS':
      return { ...state, sendFormStatus: { ...state.sendFormStatus, progress: action.progress } };
    default:
      return state;
  }
};

export const getFormDataFromUserRecord = async (record) => {
  const formData = new FormData();
  const answerData = {
    questionId: record.questionId,
    startTime: record.startTime,
    endTime: record.endTime,
    answerVideoCameraFilename: record.answerVideoCameraFilename,
    questionVideoCameraFilename: record.questionVideoCameraFilename,
  };
  const jsonData = { ...record.formAnswers, ...answerData };

  formData.append('answerData', JSON.stringify(jsonData));

  if (record.answerVideoBlobUrl) {
    const answerVideo = await fetchBlobByUrl(record.answerVideoBlobUrl);
    formData.append('answerVideo', answerVideo, 'answer.webm');
  }

  if (record.questionVideoBlobUrl) {
    const questionVideo = await fetchBlobByUrl(record.questionVideoBlobUrl);
    formData.append('questionVideo', questionVideo, 'question.webm');
  }

  if (record.answerVideoCameraFilename) {
    formData.append('answerVideoCameraFilename', record.answerVideoCameraFilename);
  }

  if (record.questionVideoCameraFilename) {
    formData.append('questionVideoCameraFilename', record.questionVideoCameraFilename);
  }

  return formData;
};

export const userHasStartedToAnswer = record => !!record.questionId || (typeof record.formAnswers !== 'undefined' && typeof record.formAnswers.firstname !== 'undefined');

export const userHasAnswered = record => typeof record !== 'undefined' && !!record.questionId && typeof record.formAnswers !== 'undefined' && typeof record.formAnswers.firstname !== 'undefined';

export default userRecord;
