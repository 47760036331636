import localForage from 'localforage';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import bootstrap from './bootstrap';
import { container } from './container';
import './helpers/date';
import { refreshVideosRequest } from './store/actions';
import getMiddlewares from './store/actions/middlewares';
import DataClient from './store/DataClient';
import reducers from './store/reducers';
import { userRecordDefaultState } from './store/reducers/userRecord';
import { setDefaultStoreDataForClient } from './helpers/clientsHelper';

window.APP_ENV = window.APP_ENV || {};
window.NODE_ENV = typeof process !== 'undefined' && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev') ? 'dev' : 'prod';

Sentry.init({
  dsn: 'https://bb7f5ec933d140e1a77903fab71f74aa@o1091454.ingest.sentry.io/6108451',
  integrations: [
    new Integrations.BrowserTracing({
      // tracingOrigins: ['thisstringwillnevermatch'], // Disable adding the sentry-trace header to all XHR requests
    }),
  ],
  tracesSampleRate: 1.0, // Only capture X% of transactions for performance monitoring.
  environment: window.location.hostname,
  enabled: window.NODE_ENV !== 'dev' && window.location.hostname !== 'localhost', // Disable sentry for dev & bornes
});

const currentHost = `//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
// API server url : using the REACT_APP_API_BASE_URL env variable or //current.url/ as default
const apiBaseUrl = window.APP_ENV.API_BASE_URL || process.env.REACT_APP_API_BASE_URL || `${currentHost}/api`;
const assetsBaseUrl = window.APP_ENV.ASSETS_BASE_URL || process.env.REACT_APP_ASSETS_BASE_URL || `${currentHost}/assets`;

let defaultStoreData = {
  env: {
    API_BASE_URL: apiBaseUrl ? apiBaseUrl.replace(/\/$/, '') : '',
    ASSETS_BASE_URL: assetsBaseUrl ? assetsBaseUrl.replace(/\/$/, '') : '',
    BUILD_TIME: process.env.REACT_APP_BUILD_TIME ? process.env.REACT_APP_BUILD_TIME : 'dev',
    GIT_REF: process.env.REACT_APP_GIT_REF || '',
    GIT_COMMIT: process.env.REACT_APP_GIT_COMMIT || '',
  },
  config: {
    language: 'en',
    clientName: null, // previously used in order to customize the UI for clients: unesco, ...
    videoRecorderChoice: null,
    videoDeviceId: null,
    audioDeviceId: null,
    videoBitsPerSecond: null,
    audioBitsPerSecond: null,
    videoMimeType: null,
    videoIdealWidth: null,
    videoTransform: null,
    sendCameraCommands: false,
    useVirtualKeyboard: false,
    useIdleTimer: false,
  },
  app: {
    lastMediaPermissionAllowDate: null,
  },
  videos: [],
  userRecord: userRecordDefaultState(),
};

defaultStoreData = setDefaultStoreDataForClient(defaultStoreData);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
const persistedReducer = persistReducer({ key: 'root', storage: localForage, whitelist: ['userRecord', 'config'] }, reducers);
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const store = createStore(persistedReducer, defaultStoreData, composeEnhancers(applyMiddleware(...getMiddlewares), sentryReduxEnhancer));

container.store = store;
container.dataClient = new DataClient(store);

store.dispatch(refreshVideosRequest());

const persistor = persistStore(store);
bootstrap(store, persistor);

window.reactLoaded = true;
