import { connect } from 'react-redux';
import LayoutWithTitle from '../../components/Layout/LayoutWithTitle';
import { userHasStartedToAnswer } from '../reducers/userRecord';

const mapStateToProps = state => ({
  userHasStartedToAnswer: userHasStartedToAnswer(state.userRecord),
  sendFormStatus: state.userRecord.sendFormStatus,
  useIdleTimer: state.config.useIdleTimer,
});

const LayoutWithTitleContainer = connect(
  mapStateToProps,
)(LayoutWithTitle);

export default LayoutWithTitleContainer;
