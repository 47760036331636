import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getMediaDevices } from '../../helpers/helpers';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
    };
  }

  componentDidMount() {
    this.fetchDevices(); // load devices names (requires user authorization)
  }

  async fetchDevices() {
    const devices = await getMediaDevices(true, this.props.setLastMediaPermissionAllowDate);
    this.setState({ devices });
  }

  render() {
    const audioDevices = this.state.devices.filter(device => device.kind === 'audioinput');
    const videoDevices = this.state.devices.filter(device => device.kind === 'videoinput');

    return (
      <div>
        <InputLabel>Client</InputLabel><br />
        <Select
          native
          value={this.props.config.clientName || ''}
          onChange={e => this.props.setClientName(e.target.value)}
        >
          <option value="">Default</option>
        </Select>
        <br /><br />

        <Typography variant="h4">Video</Typography>
        <br />
        <InputLabel>Uploader / recorder</InputLabel><br />
        <Select
          native
          value={this.props.config.videoRecorderChoice || ''}
          onChange={e => this.props.setVideoRecorderChoice(e.target.value)}
        >
          <option value="">Allow the user to choose between uploader & recorder</option>
          <option value="recorder">Only web recorder</option>
          <option value="recorder_default">Web recorder by default, but allow to change</option>
          <option value="uploader">Only file uploader</option>
          <option value="uploader_default">File uploader by default, but allow to change</option>
        </Select>

        <br /><br />
        <InputLabel>Device</InputLabel><br />
        {videoDevices.map(device => (
          <Chip
            label={device.label || device.deviceId}
            key={device.deviceId}
            color={device.deviceId === this.props.config.videoDeviceId ? 'primary' : 'secondary'}
            onClick={() => this.props.setVideoDeviceId(device.deviceId)}
          />
        ))}

        <br />
        <FormControlLabel
          control={(
            <Switch
              checked={this.props.config.sendCameraCommands}
              onChange={(e, checked) => this.props.setSendCameraCommands(checked)}
              value="true"
              color="primary"
            />
          )}
          label="This camera is controlled by the API and gphoto2"
        />

        <div>
          <TextField
            id="videoBitsPerSecond"
            label="Video BitsPerSecond"
            type="number"
            defaultValue={this.props.config.videoBitsPerSecond}
            onChange={e => this.props.setVideoBitsPerSecond(e.target.value)}
            margin="normal"
          />
          <Typography variant="body1">Default: 12 288 000 bit/s</Typography>
        </div>

        <div>
          <TextField
            id="videoIdealWidth"
            label="Video ideal width"
            type="number"
            defaultValue={this.props.config.videoIdealWidth}
            onChange={e => this.props.setVideoIdealWidth(e.target.value)}
            margin="normal"
          />
          <Typography variant="body1">Default: 1920 px (640 - 3840)</Typography>
        </div>

        <div>
          <TextField
            id="videoTransform"
            label="Video css transform"
            type="text"
            defaultValue={this.props.config.videoTransform}
            onChange={e => this.props.setVideoTransform(e.target.value)}
            margin="normal"
          />
          <Typography variant="body1">Default: none.</Typography>
          <Typography variant="body2">For 70D live preview crop: scale(1.47) translateY(24px)</Typography>
          <Typography variant="body2">For 70D live big preview crop: scale(1.26)</Typography>
        </div>

        <br />
        <InputLabel>Video codec</InputLabel><br />
        <Select
          native
          value={this.props.config.videoMimeType || ''}
          onChange={e => this.props.setVideoMimeType(e.target.value)}
        >
          <option value="" aria-label="auto" />
          <option value="video/webm">video/webm</option>
          <option value="video/webm;codecs=h264">video/webm;codecs=h264</option>
          <option value="video/webm;codecs=vp8,opus">video/webm;codecs=vp8,opus</option>
          <option value="video/webm;codecs=vp8">video/webm;codecs=vp8</option>
          <option value="video/webm;codecs=vp9,opus">video/webm;codecs=vp9,opus</option>
          <option value="video/webm;codecs=vp9">video/webm;codecs=vp9</option>
        </Select>

        <br /><br />
        <Typography variant="h4">Audio</Typography>
        {audioDevices.map(device => (
          <Chip
            label={device.label || device.deviceId}
            key={device.deviceId}
            color={device.deviceId === this.props.config.audioDeviceId ? 'primary' : 'secondary'}
            onClick={() => this.props.setAudioDeviceId(device.deviceId)}
          />
        ))}

        <div>
          <TextField
            id="audioBitsPerSecond"
            label="Audio BitsPerSecond"
            type="number"
            defaultValue={this.props.config.audioBitsPerSecond}
            onChange={e => this.props.setAudioBitsPerSecond(e.target.value)}
            margin="normal"
          />
          <Typography variant="body1">Default: 128 000 bit/s</Typography>
        </div>

        <br /><br /><br />
        <Typography variant="h4">Idle timer</Typography>

        <FormControlLabel
          control={(
            <Switch
              checked={this.props.config.useIdleTimer}
              onChange={(e, checked) => this.props.setUseIdleTimer(checked)}
              value="true"
              color="primary"
            />
          )}
          label="Go back to home after 3 minutes without activity"
        />

        <br /><br /><br />
        <Typography variant="h4">Keyboard</Typography>

        <FormControlLabel
          control={(
            <Switch
              checked={this.props.config.useVirtualKeyboard}
              onChange={(e, checked) => this.props.setUseVirtualKeyboard(checked)}
              value="true"
              color="primary"
            />
          )}
          label="Use the virtual keyboard"
        />

        <br /><br />
        <hr />

        <Typography variant="body1">
          {Object.keys(this.props.env).map(key => (
            <span key={key}>{key} = {this.props.env[key]}<br /></span>
          ))}
        </Typography>
      </div>
    );
  }
}

About.propTypes = {
  env: PropTypes.shape().isRequired,
  config: PropTypes.shape({
    clientName: PropTypes.string,
    videoRecorderChoice: PropTypes.string,
    videoDeviceId: PropTypes.string,
    audioDeviceId: PropTypes.string,
    videoBitsPerSecond: PropTypes.string,
    audioBitsPerSecond: PropTypes.string,
    videoMimeType: PropTypes.string,
    videoIdealWidth: PropTypes.string,
    videoTransform: PropTypes.string,
    sendCameraCommands: PropTypes.bool,
    useVirtualKeyboard: PropTypes.bool,
    useIdleTimer: PropTypes.bool,
  }).isRequired,
  setClientName: PropTypes.func.isRequired,
  setVideoRecorderChoice: PropTypes.func.isRequired,
  setVideoDeviceId: PropTypes.func.isRequired,
  setAudioDeviceId: PropTypes.func.isRequired,
  setVideoBitsPerSecond: PropTypes.func.isRequired,
  setAudioBitsPerSecond: PropTypes.func.isRequired,
  setVideoMimeType: PropTypes.func.isRequired,
  setVideoIdealWidth: PropTypes.func.isRequired,
  setVideoTransform: PropTypes.func.isRequired,
  setSendCameraCommands: PropTypes.func.isRequired,
  setUseVirtualKeyboard: PropTypes.func.isRequired,
  setUseIdleTimer: PropTypes.func.isRequired,
  setLastMediaPermissionAllowDate: PropTypes.func.isRequired,
};

export default About;
