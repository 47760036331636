import { connect } from 'react-redux';
import { resetUserRecord, sendFormAnswers } from '../actions';
import SendForm from '../../components/UserRecord/SendForm';
import { userHasAnswered } from '../reducers/userRecord';

const mapStateToProps = state => ({
  userHasAnswered: userHasAnswered(state.userRecord),
  sendFormStatus: state.userRecord.sendFormStatus,
  useIdleTimer: state.config.useIdleTimer,
});

const mapDispatchToProps = dispatch => ({
  sendFormAnswers: () => dispatch(sendFormAnswers()),
  resetUserRecord: () => dispatch(resetUserRecord()),
});

const SendFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendForm);

export default SendFormContainer;
