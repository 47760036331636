const video = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_VIDEO':
      return {
        id: action.id,
        filename: action.video.filename,
        language: action.video.language,
        firstname: action.video.firstname,
        location: action.video.location,
        urls: action.video.urls,
      };
    case 'UPDATE_VIDEO':
      if (state.id !== action.id) {
        return state;
      }

      return {
        ...state,
        filename: action.video.filename,
        language: action.video.language,
        firstname: action.video.firstname,
        location: action.video.location,
        urls: action.video.urls,
      };
    default:
      return state;
  }
};

const videos = (state = [], action) => {
  switch (action.type) {
    case 'ADD_VIDEO':
      return [...state, video(undefined, action)];
    case 'UPDATE_VIDEO':
      return state.map(t => video(t, action));
    case 'DELETE_VIDEO':
      return state.filter(t => t.id !== action.id);
    case 'ADD_OR_UPDATE_VIDEO':
      if (state.findIndex(t => t.id === action.id) > -1) {
        return videos(state, { ...action, type: 'UPDATE_VIDEO' });
      }

      return videos(state, { ...action, type: 'ADD_VIDEO' });
    default:
      return state;
  }
};

export default videos;
